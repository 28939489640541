import { z } from "zod";
import { productTypeEnum } from "../../../types-schemas/catalog";
import {
  dueTimeOptionsEnum,
  productAdministrationRouteEnum,
  productConcentrationSolventUnitTypeEnum,
} from "../../../types-schemas/common";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { treatmentTypeSchema } from "../Treatment/Treatment.type";

/**
 * schema and types versions
 * from first to last
 */

// v0

const treatmentSnapshotDataV0Schema = z.object({
  // tx stuff
  consultationId: z.string(),
  status: z.enum(["PLANNED", "ORDERED", "ADMINISTRED"]),
  type: treatmentTypeSchema,
  priority: z.number().nonnegative().optional(),
  qty: z.number().positive(),
  unit: productConcentrationSolventUnitTypeEnum.optional(),

  dischInstr: z.string().optional(),
  adminInstr: z.string().optional(),
  bundleId: z.string().optional(),
  bundleName: z.string().optional(),
  lotNumber: z.string().optional(),
  lotExpirationDate: z.number().positive().optional(),
  timeDue: z.number().positive().optional(),
  dateAdministred: z.number().positive().optional(),
  adminRoutes: z.array(productAdministrationRouteEnum).optional(),

  // product stuff
  pId: z.string(),
  pName: z.string(),
  pType: productTypeEnum,
  pCategoryId: z.string(), // category id is same as name?

  pSellingPrice: z.number().int().nonnegative(),
  pMinPrice: z.number().int().nonnegative(),
  pDispFees: z.number().int().nonnegative(),

  pControlled: z.boolean().optional(),
  pDiscountable: z.boolean().optional(),
  pTaxable: z.boolean().optional(),
  pIncludeInMR: z.boolean().optional(),
  pMRNotes: z.string().optional(), // https://intercom.help/shepherd-20/en/articles/5008621-automatic-product-and-service-notes
  pPrintOnInvoice: z.boolean().optional(), // otherwise add it to "other charges"? kima compress mathalan ma t7ebbehech fel invoice ka compress
  pLinkedInventoryItemId: z.string().optional(),
  pLinkedInventoryItemName: z.string().optional(),
  pUnitRatio: z.number().positive().optional(),
  invoiceId: z.string().optional(),

  //
  pTaskName: z.string().optional(),
  pTaskDueDate: z.number().positive().optional(),
  pTaskPeriod: dueTimeOptionsEnum.optional(),
  //
  pPatStat: z.enum(["STERILE", "DECEASED"]).optional(),
});

type TreatmentSnapshotDataV0 = z.infer<typeof treatmentSnapshotDataV0Schema>;

const treatmentSnapshotV0Schema = baseRecordSchema.merge(
  treatmentSnapshotDataV0Schema
);

type TreatmentSnapshotV0 = z.infer<typeof treatmentSnapshotV0Schema>;

const normalizeTreatmentSnapshotV0 = (data: any): TreatmentSnapshot => {
  try {
    const treatmentSnapshotV0: TreatmentSnapshotV0 =
      treatmentSnapshotV0Schema.parse(data);

    const { ...rest } = treatmentSnapshotV0;

    // here it's same object cause current v is 0
    const treatmentSnapshot: TreatmentSnapshot = {
      ...rest,
    };
    return treatmentSnapshot;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const TREATMENT_SNAPSHOT_VERSION = "0";

export type TreatmentSnapshotData = TreatmentSnapshotDataV0;

export type TreatmentSnapshot = BaseRecord & TreatmentSnapshotData;

export const treatmentSnapshotDataSchema = treatmentSnapshotDataV0Schema;

export const treatmentSnapshotSchema = treatmentSnapshotV0Schema;

export const normalizeTreatmentSnapshot = (data: any): TreatmentSnapshot => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeTreatmentSnapshotV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
