import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { reminderSnapshotStatusEnum } from "./ReminderSnapshot.status";

/**
 * schema and types versions
 * from first to last
 */

// v0

const reminderSnapshotDataV0Schema = z.object({
  status: reminderSnapshotStatusEnum,
  name: z.string(),
  // reminderId: z.string(), TODO idk but i think i dont need
  dueDate: z.number(),
  autocompleteProductIds: z.array(z.string()).min(1),
  notifications: z.array(z.number().int().positive()).max(5).optional(),
  clientId: z.string(),
  clientName: z.string(),
  patientId: z.string(),
  patientName: z.string(),
  organizationId: z.string(),
  txSnapshotIdCreate: z.string().optional(),
  txSnapshotIdComplete: z.string().optional(),
});

type ReminderSnapshotDataV0 = z.infer<typeof reminderSnapshotDataV0Schema>;

const reminderSnapshotV0Schema = baseRecordSchema.merge(
  reminderSnapshotDataV0Schema
);

type ReminderSnapshotV0 = z.infer<typeof reminderSnapshotV0Schema>;

const normalizeReminderSnapshotV0 = (data: any): ReminderSnapshot => {
  try {
    const reminderSnapshotV0: ReminderSnapshotV0 =
      reminderSnapshotV0Schema.parse(data);

    const { ...rest } = reminderSnapshotV0;

    // here it's same object cause current v is 0
    const reminderSnapshot: ReminderSnapshot = {
      ...rest,
    };
    return reminderSnapshot;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error, data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const REMINDER_SNAPSHOT_VERSION = "0";

export type ReminderSnapshotData = ReminderSnapshotDataV0;

export type ReminderSnapshot = BaseRecord & ReminderSnapshotData;

export const reminderSnapshotDataSchema = reminderSnapshotDataV0Schema;

export const reminderSnapshotSchema = reminderSnapshotV0Schema;

export const normalizeReminderSnapshot = (data: any): ReminderSnapshot => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeReminderSnapshotV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
