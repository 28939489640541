import { z } from "zod";
import { OrgPermission } from "../../organization";
import { SubscriptionStatus, SubscriptionType } from "../../subscriptions";

// org subscription with status - account status - licence type - permissions
// e.g.: a1-1-0-aberwd
const orgPermissionsRegex =
  /^[a-zA-Z]\d{1}-[01]-[0-9]-(?:([A-Za-z])(?!.*\1))*$/;

export const accountCustomClaims = z.object({
  orgs: z.record(z.string(), z.string().regex(orgPermissionsRegex)),
});
export type AccountCustomClaims = z.infer<typeof accountCustomClaims>;

export type AccountCustomClaimsAccountStatus = "0" | "1";
export type AccountCustomClaimsLicenceType =
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9";
// export type AccountCustomClaimsSubscriptionStatus =
//   | "0"
//   | "1"
//   | "2"
//   | "3"
//   | "4"
//   | "5"
//   | "6"
//   | "7"
//   | "8"
//   | "9";

// type OrgSubscriptionType = string &
//   RegExpMatchArray & { index: 0; input: string };
// export type AccountCustomClaimsSubscriptionType = `${OrgSubscriptionType}`;

export type ParsedAccountCustomClaims = {
  orgs: {
    // org id
    [k: string]: {
      subscription: {
        status: SubscriptionStatus;
        type: SubscriptionType;
      };
      accountStatus: AccountCustomClaimsAccountStatus;
      licenceType: AccountCustomClaimsLicenceType;
      permissions: OrgPermission[]; // "a","b","c"...
    };
  };
};

export const parseClaims = (claims: AccountCustomClaims) => {
  let result: ParsedAccountCustomClaims = {
    orgs: {},
  };

  for (const orgId in claims.orgs) {
    const orgPermission = claims.orgs[orgId];
    const [subscription, accStatus, liType, permissions] =
      orgPermission.split("-");

    const [status, type] = subscription.split("") as [
      SubscriptionStatus,
      SubscriptionType
    ];
    const orgPermissions = permissions.split("") as OrgPermission[];
    const sub = {
      status,
      type,
    };

    const accountStatus = accStatus as AccountCustomClaimsAccountStatus;
    const licenceType = liType as AccountCustomClaimsLicenceType;

    result.orgs[orgId] = {
      subscription: sub,
      accountStatus,
      licenceType,
      permissions: orgPermissions,
    };
  }

  return result;
};
