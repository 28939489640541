import { z } from "zod";

// sauce https://en.wikipedia.org/wiki/Animal_identification

export const patientExternalIdTypeEnum = z.enum([
  "ACOUSTIC_TAG",
  "ADHESIVE_TAG",
  "ANKLET",
  "BRANDING_FREEZE",
  "BRANDING_HOT_IRON",
  "CODED_WIRE_TAG",
  "COLLAR_ELECTRONIC",
  "COLLAR_NON_ELECTRONIC",
  "COWBELL",
  "EAR_TAG_ELECTRONIC",
  "EAR_TAG_NON_ELECTRONIC",
  "EARMARKING",
  "FIN_CLIPPING",
  "HAIR_DYE",
  "LEG_RING_ELECTRONIC",
  "LEG_RING_NON_ELECTRONIC",
  "MICROSHIP_IMPLANT",
  "PASSPORT",
  "PASSIVE_INTEGRATED_TRANSPONDER",
  "RUMEN_BOLUS_ELECTRONIC",
  "SEMI_PERMANENT_PAINT",
  "TATTOO",
  "TELEMETRY",
  "TOE_CLIPPING",
  "VISIBLE_IMPLENT_ELASTOMETER",
  "WING_TAG_ELECTRONIC",
  "WING_TAG_NON_ELECTRONIC",
  "OTHER",
]);
export type PatientExternalIdType = z.infer<typeof patientExternalIdTypeEnum>;
