import { Container, Stack, Text, Title } from "@mantine/core";
import { ReactNode } from "react";

export const TOS_EN = () => {
  const pp: { title: string; content: ReactNode; order: number }[] = [
    {
      title: "1. OBJECT",
      content: (
        <p>
          The purpose of these General Terms of Use is to define the rules for
          the use of VETALYST Services and the rights and duties of VETALYST and
          Users.
          <br />
          The BEITARY Platform (beitary.com) offers Users the possibility to use
          the Directory Service and Online Appointment Booking Service.
          <br />
          These General Terms apply to all registrations on the Site.
          <br />
          VETALYST reserves the right to modify or supplement these conditions
          at any time. The modifications will be updated on the Site.
          <br />
          It is important to note that BEITARY is not an emergency service.
        </p>
      ),
      order: 1,
    },
    {
      title: "2. DEFINITION OF TERMS USED",
      content: (
        <p>
          The terms used in the Contract have the following meanings:
          <ul>
            <li>
              "BEITARY Subscriber" refers to any natural person Healthcare Actor
              practicing on a self-employed basis, any private law entity
              grouping Healthcare Actors for the exercise of their profession,
              and any public law entity grouping Healthcare Actors for the
              exercise of their profession, benefiting from a subscription on
              BEITARY.
            </li>
            <li>
              "Healthcare Actor" refers to professionals operating in the
              veterinary healthcare sector, accessible on the BEITARY Platform.
            </li>
            <li>
              "Application" refers to the BEITARY Platform available from a
              smartphone by downloading from an app store or on the website.
            </li>
            <li>
              "Assistant" refers to any type of personnel assisting a Healthcare
              Actor and performing actions on behalf and for the account of this
              Healthcare Actor, such as administrative staff, medical
              secretaries, medical or dental assistants, and medical
              auxiliaries.
            </li>
            <li>
              "User Account" refers to the User's personal space for private
              use, allowing them to use the Services using their Credentials.
            </li>
            <li>
              "Contract" refers to all contractual documents, including these
              Terms of Use, definitions, and personal data protection policy,
              made available to the User, notably during the creation of the
              User Account and at any time on the website www.beitary.com.
            </li>
            <li>
              "User Data" refers to the User's and their Family's data
              (including personal data) mentioned on their User Account, shared
              with a Healthcare Actor or processed by VETALYST for the purpose
              of providing the Services, as well as any document uploaded,
              broadcast, collected, or posted by a User on the BEITARY Platform.
            </li>
            <li>
              "Personal Data" refers to any information concerning an identified
              or identifiable natural person in accordance with Organic Law No.
              2004-63 of July 27, 2004.
            </li>
            <li>
              "Equipment" refers to any type of equipment allowing access to and
              use of the Services, such as computers, headsets, microphones,
              webcams, telephones, and tablets.
            </li>
            <li>
              "Identifier" refers to the personal login identifier associated
              with a User Account as well as any confidential code or complex
              password issued by VETALYST or created and modified by the User to
              identify themselves and access the Services.
            </li>
            <li>
              "Confidential Information" refers to any financial, legal,
              commercial, technical, computer-related, or administrative
              information, as well as any other information that could promote
              the interests of a competing company and that is communicated
              directly or indirectly in writing, orally, or in any other form
              and medium considered confidential due to its nature or the
              circumstances of its disclosure. Confidential information does not
              include information already public, known to the receiving Party
              before its communication or disclosure, legally obtained from a
              third party, or authorized in writing by the other Party before
              its disclosure.
            </li>
            <li>"Parties" refers to VETALYST and the User.</li>
            <li>
              "Data Subject" is defined by Organic Law No. 2004-63 of July 27,
              2004.
            </li>
            <li>
              "BEITARY Platform" refers to the software package accessible
              online on the website www.beitary.com or mobile accessible via the
              various market "Apps stores", allowing the User to consult the
              BEITARY directory or make an appointment online.
            </li>
            <li>
              "Family" refers to any natural person authorized by the User or
              mandated by law to make an appointment online directly on their
              User Account or to transfer their Personal Data to the BEITARY
              Platform.
            </li>
            <li>
              "Intellectual Property" refers to all intellectual property
              rights, including copyrights, neighboring rights, copyright,
              designs and models, software and source codes, trademarks, trade
              names and logos, domain names and other distinctive signs,
              databases, as well as inventions, patents and know-how, and
              personality rights, the right to privacy and the right to image.
            </li>
            <li>
              "Data Controller" is defined by Organic Law No. 2004-63 of July
              27, 2004.
            </li>
            <li>
              "Services" refers to the Service(s) made available to Users,
              including notably the Directory Service and Online Appointment
              Booking Service.
            </li>
            <li>
              "Directory Service" refers to the online search service for
              Healthcare Actors offered by VETALYST, including the name,
              specialty, contact information, access information, rates, hours,
              and expertise of Healthcare Actors.
            </li>
            <li>
              "Online Appointment Booking Service" refers to the service
              allowing the User to book appointments online with a Healthcare
              Actor subscribed to BEITARY via the Site or Mobile Application.
            </li>
            <li>
              "Electronic Signature" refers to an electronic process allowing a
              person to consent to the content of an electronic document by
              clicking on a digital acceptance button, for example.
            </li>
            <li>"Site" refers to the website www.beitary.com.</li>
            <li>
              "Subcontractor" is defined by Organic Law No. 2004-63 of July 27,
              2004.
            </li>
            <li>
              "Processing" is defined by Organic Law No. 2004-63 of July 27,
              2004.
            </li>
            <li>
              "User" refers to any natural person who has a User Account on the
              BEITARY Platform and accesses the Services via the Site, as well
              as any Visitor to the Site.
            </li>
            <li>
              "Site Visitor" refers to any natural person browsing the website
              www.beitary.com.
            </li>
          </ul>
        </p>
      ),
      order: 1,
    },
    {
      title: "3. USER ACCOUNT CREATION",
      content: (
        <p>
          To access the appointment booking and teleconsultation service, you
          must create an online User Account. During this creation, you agree to
          provide accurate and complete information about your identity in
          accordance with the online form. You agree not to create a false
          identity or impersonate another person. You must update your data in
          case of any subsequent changes. VETALYST reserves the right to suspend
          or refuse access to the Services in case of false, inaccurate,
          outdated, incomplete, or misleading information provided by the User.
        </p>
      ),
      order: 1,
    },
    {
      title: "4. ACCESS CONDITIONS TO THE SERVICES",
      content: (
        <p>
          You can access the Directory Service without creating an account.
          However, to access the Online Appointment Booking Services, you must
          create a User Account and accept these TOS. By creating your User
          Account, you expressly accept the TOS. If you do not agree with the
          TOS, you must not use the Services.
        </p>
      ),
      order: 1,
    },
    {
      title: "5. TECHNICAL SPECIFICATIONS FOR ACCESS TO THE SERVICES",
      content: (
        <p>
          You are responsible for the confidentiality of your Credentials. In
          case of loss or theft, you must inform VETALYST to cancel or update
          the relevant Credentials. You are solely responsible for actions taken
          from your User Account.
        </p>
      ),
      order: 1,
    },
    {
      title: "6. VETALYST'S OBLIGATIONS AND RESPONSIBILITIES",
      content: (
        <p>
          VETALYST undertakes to provide the Services with professionalism and
          in compliance with applicable standards. VETALYST ensures the security
          and confidentiality of Client data. However, VETALYST declines any
          responsibility in case of difficulties accessing the Site, suspension
          of Services for maintenance, or non-performance of medical acts.
        </p>
      ),
      order: 1,
    },
    {
      title: "7. SMS, EMAILS, AND PHONE CALLS TO THE USER",
      content: (
        <p>
          By using the Services, you authorize VETALYST to send you SMS, emails,
          and phone calls for appointment confirmation, cancellation, or
          reminders. You can opt out of receiving these communications.
        </p>
      ),
      order: 1,
    },
    {
      title: "8. USER'S GENERAL OBLIGATIONS",
      content: (
        <p>
          You are responsible for the use of your User Account and agree to
          comply with applicable laws and regulations. You must also adhere to
          the rules set forth in the Contract and provide VETALYST with
          necessary documentation.
        </p>
      ),
      order: 1,
    },
    {
      title: "9. DIRECTORY SERVICE",
      content: (
        <p>
          The Directory Service allows you to search for a Healthcare Actor
          based on various criteria. VETALYST does not guarantee that the
          directory contains all Healthcare Actors. The Directory Service is not
          a service for addressing Patients to Healthcare Actors.
        </p>
      ),
      order: 1,
    },
    {
      title: "10. ONLINE APPOINTMENT BOOKING SERVICE",
      content: (
        <p>
          You can schedule an appointment online with a Healthcare Actor using
          the Online Appointment Booking Service. VETALYST cannot be held
          responsible for the cancellation or unavailability of the Healthcare
          Actor.
        </p>
      ),
      order: 1,
    },
    {
      title: "11. PROTECTION OF PERSONAL DATA",
      content: (
        <p>
          The personal data collected is processed by VETALYST Tunisia for the
          provision of Services and sending of Service-related information. You
          can access and modify your personal data on the Site.
        </p>
      ),
      order: 1,
    },
    {
      title: "12. COOKIES",
      content: (
        <p>
          Please refer to the Cookie Policy for more information on the use of
          cookies by VETALYST.
        </p>
      ),
      order: 1,
    },
    {
      title: "13. DATA TRANSFER TO THE APPLICATION",
      content: (
        <p>
          By using the Services, you authorize VETALYST to transfer your data
          necessary for optimal use of the Services.
        </p>
      ),
      order: 1,
    },
    {
      title: "14. TERMINATION",
      content: (
        <p>
          VETALYST may terminate all or part of the Services without
          compensation. In case of serious fault by the User, VETALYST may
          terminate all or part of the Services. You can terminate the Contract
          by requesting deletion of your User Account.
        </p>
      ),
      order: 1,
    },
    {
      title: "15. INTELLECTUAL PROPERTY",
      content: (
        <p>
          The Services and their components are the exclusive property of
          VETALYST. You have a personal right to use the BEITARY Platform, but
          you may not copy, reproduce, or modify the Platform.
        </p>
      ),
      order: 1,
    },
    {
      title: "16. LEGAL MENTIONS",
      content: (
        <p>
          BEITARY is operated by VETALYST, a SUARL company headquartered at
          Route Gremda km 8 3012 Sfax.
        </p>
      ),
      order: 1,
    },
    {
      title: "17. AMICABLE SETTLEMENT - APPLICABLE LAW AND JURISDICTION",
      content: (
        <p>
          In case of dispute, VETALYST and the User will seek an amicable
          resolution to their differences. In the absence of an amicable
          agreement, any dispute shall be subject to the jurisdiction of the
          competent Courts under Tunisian law.
        </p>
      ),
      order: 1,
    },
    {
      title: "18. MISCELLANEOUS",
      content: (
        <p>
          VETALYST may send satisfaction surveys to Users to evaluate the
          quality of Services, but participation in these surveys is optional.
        </p>
      ),
      order: 1,
    },
  ];
  return (
    <Container size="md">
      <Stack pt="xl">
        <Title mb="xl" mt="xl">
          Terms of Service
        </Title>
        {pp.map((p) => (
          <div>
            <Title order={p.order === 1 ? 2 : 3}>{p.title}</Title>
            <Text>{p.content}</Text>
          </div>
        ))}
      </Stack>
    </Container>
  );
};
