import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../../baseRecord";
import { genericStatusEnum } from "../../../common/status/GenericStatus";
import { UnableToNormalizeData } from "../../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { bFileCategoriesEnum } from "./BFile.category";

/**
 * schema and types versions
 * from first to last
 */

// v0

const bFileDataV0Schema = z.object({
  status: genericStatusEnum,
  fileCategory: bFileCategoriesEnum,
  dlURL: z.string().url(),
  thumbURL: z.string().url().optional(),
  consultationId: z.string().optional(),

  fileSize: z.number(),
  contentType: z.string(),
  originalName: z.string(),
  readOrgIds: z.record(z.string(), z.boolean()),
  organizationId: z.string(),
  clientId: z.string().optional(),
  patientId: z.string().optional(),

  shareWithClient: z.enum(["true", "false"]), // boolean
  shareReadWithSelectedOrgs: z.enum(["true", "false"]), // boolean
  comment: z.string().optional(),
});

type BFileDataV0 = z.infer<typeof bFileDataV0Schema>;

const bFileV0Schema = baseRecordSchema.merge(bFileDataV0Schema);

type BFileV0 = z.infer<typeof bFileV0Schema>;

const normalizeBFileV0 = (data: any): BFile => {
  try {
    const bFileV0: BFileV0 = bFileV0Schema.parse(data);

    const { ...rest } = bFileV0;

    // here it's same object cause current v is 0
    const bFile: BFile = {
      ...rest,
    };
    return bFile;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const BFILE_VERSION = "0";

export type BFileData = BFileDataV0;

export type BFile = BaseRecord & BFileData;

export const bFileDataSchema = bFileDataV0Schema;

export const bFileSchema = bFileV0Schema;

export const normalizeBFile = (data: any): BFile => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeBFileV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
