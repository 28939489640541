import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { genericStatusEnum } from "../../common/status/GenericStatus";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

const permissionsRegex = /^(?:([A-Za-z])(?!.*\1))*$/;

const memberRoleDataV0Schema = z.object({
  status: genericStatusEnum,
  name: z.string(),
  description: z.string().optional(),
  permissions: z.string().regex(permissionsRegex),
});

type MemberRoleDataV0 = z.infer<typeof memberRoleDataV0Schema>;

const memberRoleV0Schema = baseRecordSchema.merge(memberRoleDataV0Schema);

type MemberRoleV0 = z.infer<typeof memberRoleV0Schema>;

const normalizeMemberRoleV0 = (data: any): MemberRole => {
  try {
    const memberRoleV0: MemberRoleV0 = memberRoleV0Schema.parse(data);

    const { ...rest } = memberRoleV0;

    // here it's same object cause current v is 0
    const memberRole: MemberRole = {
      ...rest,
    };
    return memberRole;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const MEMBER_ROLE_VERSION = "0";

export type MemberRoleData = MemberRoleDataV0;

export type MemberRole = BaseRecord & MemberRoleData;

export const memberRoleDataSchema = memberRoleDataV0Schema;

export const memberRoleSchema = memberRoleV0Schema;

export const normalizeMemberRole = (data: any): MemberRole => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeMemberRoleV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
