import { z } from "zod";
import {
  currencyEnum,
  languagesEnum,
  memberLicenceType,
  subscriptionStatusEnum,
  subscriptionTypeEnum,
  timezoneEnum,
  UnableToNormalizeData,
} from "../../../types-schemas";
import { phone_utils } from "../../../utils";

export const rNOOnCallDataSchema = z
  .object({
    // org
    orgLicenseNumber: z.string(),
    orgName: z.string(),
    orgEmail: z.string().email(),
    orgLogoURL: z.string().url().optional(),
    orgPhoneNumber: z.string(),
    orgPhoneNumber_countryCode: z.string(),
    orgAddress_Line1: z.string(),
    orgAddress_Line2: z.string().optional(),
    orgAddress_city: z.string(),
    orgAddress_stateOrProvince: z.string().optional(),
    orgAddress_postalCode: z.string(),
    orgAddress_countryCode: z.string(),
    orgLanguage: languagesEnum.default("FR"),
    orgCurrency: currencyEnum.default("TND"),
    orgTimeZone: timezoneEnum.default("Africa/Tunis"),
    orgTemperatureUnit: z.enum(["C", "F"]).default("C"),

    // owner account
    ownerName: z.string(),
    ownerEmail: z.string().email(),
    ownerPictureURL: z.string().url().optional(),
    ownerPhoneNumber: z.string(),
    ownerPhoneNumber_countryCode: z.string(),
    ownerAddress_Line1: z.string().optional(),
    ownerAddress_Line2: z.string().optional(),
    ownerAddress_city: z.string().optional(),
    ownerAddress_stateOrProvince: z.string().optional(),
    ownerAddress_postalCode: z.string().optional(),
    ownerAddress_countryCode: z.string(),

    // onwer member
    ownerInitials: z.string().max(3).min(1),
    // ownerRolesIds: z.array(z.string()).min(1), role will be created first then assigned
    ownerLicenceType: memberLicenceType.optional(),
    ownerHasAgreedToTOSAndPP: z.boolean(),

    // subscription
    subscriptionType: subscriptionTypeEnum,
    subscriptionStatus: subscriptionStatusEnum,
    subscriptionDescription: z.string(),
    subscriptionCurrency: currencyEnum.default("TND"),

    // subscriptionCustomerId: z.string(), org will be created first then assigned

    // TODO fix these later
    // subscriptionCurrentPeriodEnd: z.number().int().positive().optional(),
    // subscriptionCurrentPeriodStart: z.number().int().positive().optional(),
    // subscriptionDueDate: z.number().int().positive().optional(), // TODO include only if not trialing
    // subscriptionDueAmount: z.number().int().positive().optional(), // TODO include only if not trialing
    // subscriptionEndAt: z.number().int().optional(), // should not be specified I guess
    // subscriptionEndAtPeriodEnd: z.boolean().optional(),
    // subscriptionEndedAt: z.number().int().positive().optional(),

    subscriptionStartDate: z.number().int().positive(),
    // subscriptionTrialStartAt: z.number().int().positive().optional(),
    // subscriptionTrialEndAt: z.number().int().positive().optional(),
  })
  .refine((data) => data.ownerHasAgreedToTOSAndPP, {
    message: "USER_MUST_AGREE_TO_TOS_AND_PP",
  })
  .refine(
    (data) =>
      data.orgPhoneNumber &&
      phone_utils.parse(data.orgPhoneNumber, data.orgPhoneNumber_countryCode),
    {
      // t("INVALID_PHONE_NUMBER")
      message: "INVALID_PHONE_NUMBER",
      path: ["orgPhoneNumber"],
    }
  )
  .refine(
    (data) =>
      data.ownerPhoneNumber &&
      phone_utils.parse(
        data.ownerPhoneNumber,
        data.ownerPhoneNumber_countryCode
      ),
    {
      // t("INVALID_PHONE_NUMBER")
      message: "INVALID_PHONE_NUMBER",
      path: ["ownerPhoneNumber"],
    }
  );

export type RNOOnCallData = z.infer<typeof rNOOnCallDataSchema>;

export const normalizeRNOOnCallData = (data: any): RNOOnCallData => {
  try {
    return rNOOnCallDataSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

// not nullable, backend will throw error if something goes wrong
export const rNOOnCallResponseSchema = z.object({
  contentType: z.enum(["application/json"]), // idk wtf this is, maybe no need for this at all
  content: z.string(), // org id
});

export type RNOOnCallResponse = z.infer<typeof rNOOnCallResponseSchema>;

export const normalizeRNOOnCallResponse = (data: any): RNOOnCallResponse => {
  try {
    return rNOOnCallResponseSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};
