import { selectLanguage } from "app/App.slice";
import { useAppSelector } from "app/hooks";
import { PP } from "features/PP/PP";
import { PP_EN } from "features/PP/PP_EN";
import { TOS } from "features/TOS/TOS";
import { TOS_EN } from "features/TOS/TOS_EN";
import { Contact } from "features/contact/Contact";
import { Home } from "features/home/Home";
import { ScheduleDemo } from "features/schedule-demo/ScheduleDemo";
import { Route, Routes as Switch } from "react-router-dom";

export const RouterConfig = () => {
  const language = useAppSelector(selectLanguage);
  return (
    <Switch>
      <Route path="/" element={<Home />} />
      <Route path="/pp" element={language === "EN" ? <PP_EN /> : <PP />} />
      <Route path="/tos" element={language === "EN" ? <TOS_EN /> : <TOS />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/schedule-demo" element={<ScheduleDemo />} />
      {/* <Route path="/organizations" element={<Outlet />}>
        <Route index element={<Organizations />} />
        <Route path=":organizationId" element={<>hi</>} />
        <Route path="edit/:organizationId" element={<EditOrganizationForm />} />
        <Route path="new" element={<NewOrganizationForm />} />
      </Route>
      <Route path="/mail-templates" element={<Outlet />}>
        <Route index element={<MailTemplates />} />
        <Route path=":mailTemplateId" element={<ViewMailTemplate />} />
        <Route path=":mailTemplateId/edit" element={<EditMailTemplate />} />
        <Route path="new" element={<AddMailTemplate />} />
      </Route>
      <Route path="/sms-templates" element={<Outlet />}>
        <Route index element={<SMSTemplates />} />
        <Route path=":smsTemplateId" element={<ViewSMSTemplate />} />
        <Route path=":smsTemplateId/edit" element={<EditSMSTemplate />} />
        <Route path="new" element={<AddSMSTemplate />} />
      </Route> */}
    </Switch>
  );
};

// this is for the translation solely
// t("Contact")
// t("Politique de Confidentialité")
// t("Conditions Générales d'Utilisation")
