import {
  Anchor,
  AppShell,
  Box,
  Button,
  Container,
  Flex,
  Group,
  Header,
  Image,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { selectLanguage } from "app/App.slice";
import { useAppSelector } from "app/hooks";
import { BTRLogogWhiteImg, logoImg } from "assets";
import { RouterConfig } from "navigation/RouterConfig";
import { useEffect } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const MyApp = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const userLanguage = useAppSelector(selectLanguage);

  console.log(userLanguage);

  useEffect(() => {
    const changeLanguageHandler = (lang: string) => {
      i18n.changeLanguage(lang);
    };

    // console.log(`new lang: ${language}`);

    changeLanguageHandler(userLanguage);
  }, [userLanguage, i18n]);

  const largerThanSM = useMediaQuery("(min-width: 48rem)");

  return (
    <AppShell
      fixed
      header={
        <>
          {largerThanSM && (
            <Header height={90}>
              <Group position="apart" mt="xs">
                <Box p="xs" ml="xl" maw={200}>
                  <UnstyledButton onClick={() => navigate("/")}>
                    <Image
                      src={logoImg}
                      height={42}
                      width={150}
                      fit="scale-down"
                    />
                  </UnstyledButton>
                </Box>

                <Group mr="xl" pr="xl">
                  <Anchor key={"contact"} onClick={() => navigate("/contact")}>
                    {t("Contact")}
                  </Anchor>
                  <Button
                    radius="sm"
                    size="sm"
                    component="a"
                    href="https://cloudvet.beitary.com"
                    target="_blank"
                  >
                    {t("Log in")}
                  </Button>
                </Group>
              </Group>
            </Header>
          )}
          {!largerThanSM && (
            <Header height={80}>
              <Group position="apart" m="md" mt="xl">
                <Box pl="xs" ml="xs" maw={100}>
                  <UnstyledButton onClick={() => navigate("/")}>
                    <Image src={logoImg} height={32} fit="scale-down" />
                  </UnstyledButton>
                </Box>

                <Group mr="xl">
                  <Anchor
                    size="sm"
                    key={"contact"}
                    onClick={() => navigate("/contact")}
                  >
                    {t("Contact")}
                  </Anchor>
                  <Button
                    radius="md"
                    size="xs"
                    component="a"
                    href="https://cloudvet.beitary.com"
                    target="_blank"
                  >
                    {t("Log in")}
                  </Button>
                </Group>
              </Group>
            </Header>
          )}
        </>
      }
      footer={<FooterSimple />}
    >
      <RouterConfig />
    </AppShell>
  );
};

export const App = withTranslation()(MyApp);

function FooterSimple() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const largerThanSM = useMediaQuery("(min-width: 48rem)");

  const links: { link: string; label: string }[] = [
    { label: t("Contact"), link: "/contact" },
    { label: t("Politique de Confidentialité"), link: "/pp" },
    {
      label: t("Conditions Générales d'Utilisation"),
      link: "/tos",
    },
  ];
  const items = links.map((link) => (
    <Anchor size="xs" c="white" onClick={() => navigate(link.link)}>
      {link.label}
    </Anchor>
  ));

  return (
    <Container fluid h={150} bg="cyan" p="xl">
      {largerThanSM ? (
        <Group position="apart" h="100%" align="flex-end">
          <Box p="xs" maw={200} mb="-xs">
            <Image src={BTRLogogWhiteImg} width={40} fit="scale-down" />
          </Box>
          <Text size="xs" color="white">
            © BEITARY VETERINARY SOFTWARE
          </Text>
          <Group>{items}</Group>
        </Group>
      ) : (
        <Flex
          direction={{ base: "column", sm: "row" }}
          gap={{ base: 2, sm: "xl" }}
          align="center"
          h="100%"
          w="100%"
        >
          <Box p="xs" maw={200} mb="-xs">
            <Image src={BTRLogogWhiteImg} width={40} fit="scale-down" />
          </Box>
          <Text size="xs" color="white">
            © BEITARY VETERINARY SOFTWARE
          </Text>
          {items}
        </Flex>
      )}
    </Container>
  );
}
