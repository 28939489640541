import { z } from "zod";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

export const mailSchema = z.object({
  to: z.array(z.string().email()).optional(),
  toUids: z.array(z.string()).optional(),
  template: z
    .object({
      name: z.string(),
      data: z.any(),
    })
    .optional(),
  message: z
    .object({
      subject: z.string(),
      html: z.string(),
    })
    .optional(),
});

export type Mail = z.infer<typeof mailSchema>;

export const normalizeMail = (data: any): Mail => {
  try {
    const mail: Mail = mailSchema.parse(data);
    return mail;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};
