import { z } from "zod";
import { languagesEnum, UnableToNormalizeData } from "../../../types-schemas";

export const sUOnHTTPRequestDataSchema = z.object({
  name: z.string(),
  email: z.string(),
  password: z.string(),
  phoneNumber: z.string(),
  phoneNumber_countryCode: z.string(),
  pictureURL: z.string().url().optional(),
  language: languagesEnum,
  address_Line1: z.string().optional(),
  address_Line2: z.string().optional(),
  address_city: z.string().optional(),
  address_stateOrProvince: z.string().optional(),
  address_postalCode: z.string().optional(),
  address_countryCode: z.string().optional(),
});

export type SUOnHTTPRequestData = z.infer<typeof sUOnHTTPRequestDataSchema>;

export const normalizeSUOnHTTPRequestData = (
  data: any
): SUOnHTTPRequestData => {
  try {
    return sUOnHTTPRequestDataSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

export const sUOnHTTPRequestResponseSchema = z.object({
  uid: z.string(),
});

export type SUOnHTTPRequestResponse = z.infer<
  typeof sUOnHTTPRequestResponseSchema
>;

export const normalizeSUOnHTTPRequestResponse = (
  data: any
): SUOnHTTPRequestResponse => {
  try {
    return sUOnHTTPRequestResponseSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};
