import { Container, Stack, Text, Title } from "@mantine/core";
import { ReactNode } from "react";

export const PP_EN = () => {
  const pp: { title: string; content: ReactNode; order: number }[] = [
    {
      title: "Context",
      content: (
        <p>
          At VETALYST, we consider our users' trust to be paramount. Thus, the
          security and confidentiality of their personal data are our top
          priority.
          <br />
          We are committed to complying with all Tunisian regulations regarding
          the protection of personal data.
          <br />
          We have implemented a strict policy to ensure the protection of our
          users' health data, in accordance with Organic Law No. 2004-63 of July
          27, 2004.
          <br />
        </p>
      ),
      order: 1,
    },
    {
      title: "Purpose of this policy",
      content: (
        <p>
          This policy aims to inform you about how we protect your personal data
          processed through our site https://www.beitary.com or our mobile
          application.
          <br />
          It describes how we handle the personal data of our users when they
          browse the site or use our application.
          <br />
          This policy may be modified in case of legal changes, but our users'
          personal data will always be processed in accordance with the policy
          in effect at the time of their collection, unless otherwise required
          by law.
        </p>
      ),
      order: 1,
    },
    {
      title: "Identity and contact details of the data controllers",
      content: (
        <p>
          VETALYST is the data controller for your personal data. We take all
          necessary measures to ensure their protection and confidentiality, in
          accordance with Organic Law No. 2004-63 of July 27, 2004.
        </p>
      ),
      order: 1,
    },
    {
      title: "Collection and origin of data",
      content: (
        <p>
          All data concerning our users is collected directly from them, by
          VETALYST.
          <br />
          We commit to obtaining their consent or allowing them to object to the
          use of their data for certain purposes, if necessary.
        </p>
      ),
      order: 1,
    },
    {
      title: "Purpose of data collection",
      content: (
        <p>
          The personal data of our users is primarily processed to enable their
          navigation on the site, to connect them with a health professional,
          and to view and manage their care pathway.
          <br />
          This data is also collected to prevent computer fraud, improve site
          navigation, and perform statistics on the use of our tool.
        </p>
      ),
      order: 1,
    },
    {
      title: "Types of data processed",
      content: (
        <p>
          We use our users' personal data for different purposes, such as online
          appointment booking and user account management. Here are the types of
          personal data we use for each purpose, the legal basis on which they
          are processed, and the duration of data retention:
        </p>
      ),
      order: 1,
    },
    {
      title: "1. Online appointment booking",
      content: (
        <p>
          <ul>
            <li>
              Data used: name, first name, phone number/email, reasons for
              consultation (optional), practitioner's name and first name,
              practitioner's specialty, appointment date
            </li>
            <li>Legal basis: Performance of a contract (TOS)</li>
            <li>Data retention period: until the user's deletion request.</li>
          </ul>
        </p>
      ),
      order: 2,
    },
    {
      title: "2. User account management",
      content: (
        <p>
          <ul>
            <li>
              Data used: name, first name, password, email address, phone
              number, account data (creation date and time)
            </li>
            <li>Legal basis: Performance of a contract (TOS)</li>
            <li>Data retention period: until the user's deletion request.</li>
          </ul>
        </p>
      ),
      order: 2,
    },
    {
      title: "3. Use of applications and devices",
      content: (
        <p>
          <ul>
            <li>
              Data used: connection and usage data of the platform (site visit
              date and time, session ID), hardware used for navigation, IP
              address
            </li>
            <li>Legal basis: Legitimate interest</li>
            <li>Data retention period: until the user's deletion request.</li>
          </ul>
        </p>
      ),
      order: 2,
    },
    {
      title: "4. Prevention and fight against computer fraud",
      content: (
        <p>
          <ul>
            <li>
              Data used: connection and site usage data, hardware used for
              navigation, IP address
            </li>
            <li>Legal basis: Legitimate interest</li>
            <li>Data retention period: until the user's deletion request.</li>
          </ul>
        </p>
      ),
      order: 2,
    },
    {
      title: "5. Rights requests",
      content: (
        <p>
          <ul>
            <li>
              Data used: name, first name, email address, content of the
              request, ID document (if necessary)
            </li>
            <li>Legal basis: Legal obligation</li>
            <li>Data retention period: until the user's deletion request.</li>
          </ul>
        </p>
      ),
      order: 2,
    },
    {
      title: "Non-disclosure of personal data",
      content: (
        <p>
          We assure you that your personal data will not be transmitted to
          commercial or advertising actors. However, we may use specialized
          companies for certain services such as mailing or audience analysis.
          In this case, we have concluded specific contracts with these
          companies to frame and secure the transfer of your data.
        </p>
      ),
      order: 1,
    },
    {
      title: "Data retention period",
      content: (
        <p>
          We retain your personal data until your deletion request, in
          accordance with legal requirements.
        </p>
      ),
      order: 1,
    },
    {
      title: "User rights",
      content: (
        <p>
          You have certain rights regarding your personal data, in accordance
          with current regulations. You have the right to access your data,
          rectify it, delete it, limit its processing, oppose its processing,
          transfer it to a third party, and define its fate after your death. In
          case of death, we commit to destroying your data, unless their
          retention is necessary for probative purposes or to respond to a legal
          obligation.
          <br />
          To exercise your rights, you can contact us in writing at the
          following address: VETALYST – Route Gremda km 8 3012 Sfax or by email
          at contact@vetalyst.com. Please identify yourself precisely.
        </p>
      ),
      order: 1,
    },
    {
      title: "Security",
      content: (
        <p>
          We implement all necessary technical and organizational measures to
          ensure the security of your personal data and maintain their
          confidentiality. We use authentication processes with personal and
          secure access via confidential identifiers and passwords. Some data is
          also encrypted to enhance security.
        </p>
      ),
      order: 1,
    },
    {
      title: "Personal information and minors",
      content: (
        <p>
          Our site and application are intended for adults capable of entering
          into legal obligations. Minor users under the age of 16 or
          incapacitated persons must obtain prior consent from their legal
          guardian before entering their data on our site and application.
          Unless legally obligated to maintain confidentiality, we may inform
          the legal guardian directly of the specific categories of personal
          data collected from the minor and the possibility to object to the
          collection, use, or retention of this data.
        </p>
      ),
      order: 1,
    },
    {
      title: "Contact us",
      content: (
        <p>
          If you have any questions, complaints, or recommendations regarding
          our personal data protection policy, you can contact us in writing at
          the following address: VETALYST – Route Gremda km 8 3012 Sfax or by
          email at contact@vetalyst.com. We remain at your disposal to improve
          the quality of our privacy policy.
        </p>
      ),
      order: 1,
    },
  ];
  return (
    <Container size="md">
      <Stack pt="xl">
        <Title mb="xl" mt="xl">
          Personal Data Protection Policy
        </Title>
        {pp.map((p) => (
          <>
            <Title order={p.order === 1 ? 2 : 3}>{p.title}</Title>
            <Text>{p.content}</Text>
          </>
        ))}
      </Stack>
    </Container>
  );
};
