import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../../baseRecord";
import { UnableToNormalizeData } from "../../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { invoiceLineItemSchema } from "../InvoiceLineItem";
import { invoicePaymentSchema } from "../InvoicePayment";

/**
 * schema and types versions
 * from first to last
 */

// v0

const invoiceFinancesRecordDataV0Schema = z.object({
  invoiceId: z.string(),
  lineItems: z.record(invoiceLineItemSchema),
  payments: z.record(invoicePaymentSchema),
  // refunds: z.record(paymentSchema), // do we even need this? just hvae them as payments?
  // and if invoice balace > 0 => take payment
  // if balance < 0 => issue refund (= negative payment)
});

type InvoiceFinancesRecordDataV0 = z.infer<
  typeof invoiceFinancesRecordDataV0Schema
>;

const invoiceFinancesRecordV0Schema = baseRecordSchema.merge(
  invoiceFinancesRecordDataV0Schema
);

type InvoiceFinancesRecordV0 = z.infer<typeof invoiceFinancesRecordV0Schema>;

const normalizeInvoiceFinancesRecordV0 = (data: any): InvoiceFinancesRecord => {
  try {
    const invoiceFinancesRecordV0: InvoiceFinancesRecordV0 =
      invoiceFinancesRecordV0Schema.parse(data);

    const { ...rest } = invoiceFinancesRecordV0;

    // here it's same object cause current v is 0
    const invoiceFinancesRecord: InvoiceFinancesRecord = {
      ...rest,
    };
    return invoiceFinancesRecord;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const INVOICE_FINANCES_RECORD_VERSION = "0";

export type InvoiceFinancesRecordData = InvoiceFinancesRecordDataV0;

export type InvoiceFinancesRecord = BaseRecord & InvoiceFinancesRecordData;

export const invoiceFinancesRecordDataSchema =
  invoiceFinancesRecordDataV0Schema;

export const invoiceFinancesRecordSchema = invoiceFinancesRecordV0Schema;

export const normalizeInvoiceFinancesRecord = (
  data: any
): InvoiceFinancesRecord => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeInvoiceFinancesRecordV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
