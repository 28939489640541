import { z } from "zod";

// t("MALE")
// t("FEMALE")
// t("MALE_NEUTERED")
// t("FEMALE_SPAYED")
// t("UNDETERMINED")
export const patientSexEnum = z.enum([
  "MALE",
  "FEMALE",
  "MALE_NEUTERED",
  "FEMALE_SPAYED",
  "UNDETERMINED",
]);
export type PatientSex = z.infer<typeof patientSexEnum>;
