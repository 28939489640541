import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../../baseRecord";
import { UnableToNormalizeData } from "../../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

const invoiceLineItemDataV0Schema = z.object({
  pName: z.string(),
  pId: z.string(),
  pLinkedInventoryItemId: z.string().optional(),
  pLinkedInventoryItemName: z.string().optional(),
  category: z.string(),
  bundleId: z.string().optional(),
  bundleName: z.string().optional(),
  discount: z.number().int().nonnegative().optional(),
  qty: z.number().nonnegative(),
  price: z.number().int(), // removed non negative for refund
  invoiceId: z.string(),
  consultationId: z.string().optional(),
  patientId: z.string().optional(),
  patientName: z.string().optional(),
  parentId: z.string(),
  parentType: z.enum([
    "PRESCRIPTION",
    "BUNDLE",
    "SERVICE",
    "TREATMENT",
    "VACCINE",
    "INVOICE",
    "RETURN", // added this for returns, so that price is negative
  ]),
});

type InvoiceLineItemDataV0 = z.infer<typeof invoiceLineItemDataV0Schema>;

const invoiceLineItemV0Schema = baseRecordSchema.merge(
  invoiceLineItemDataV0Schema
);

type InvoiceLineItemV0 = z.infer<typeof invoiceLineItemV0Schema>;

const normalizeInvoiceLineItemV0 = (data: any): InvoiceLineItem => {
  try {
    const invoiceLineItemV0: InvoiceLineItemV0 =
      invoiceLineItemV0Schema.parse(data);

    const { ...rest } = invoiceLineItemV0;

    // here it's same object cause current v is 0
    const invoiceLineItem: InvoiceLineItem = {
      ...rest,
    };
    return invoiceLineItem;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const INVOICE_LINE_ITEM_VERSION = "0";

export type InvoiceLineItemData = InvoiceLineItemDataV0;

export type InvoiceLineItem = BaseRecord & InvoiceLineItemData;

export const invoiceLineItemDataSchema = invoiceLineItemDataV0Schema;

export const invoiceLineItemSchema = invoiceLineItemV0Schema;

export const normalizeInvoiceLineItem = (data: any): InvoiceLineItem => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeInvoiceLineItemV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
