import { z } from "zod";
import { genericStatusEnum } from "../../../types-schemas/common/status/GenericStatus";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

const inventoryItemManufacturerDataV0Schema = z.object({
  status: genericStatusEnum,
  name: z.string(),
  email: z.string().email().optional(),
  website: z.string().optional(),
  phoneNumber: z.string().optional(),
  phoneNumber_countryCode: z.string().optional(),
  note: z.string().optional(),
  // address
  address_Line1: z.string().optional(),
  address_Line2: z.string().optional(),
  address_city: z.string().optional(),
  address_stateOrProvince: z.string().optional(),
  address_postalCode: z.string().optional(),
  address_countryCode: z.string().optional(),
});

type InventoryItemManufacturerDataV0 = z.infer<
  typeof inventoryItemManufacturerDataV0Schema
>;

const inventoryItemManufacturerV0Schema = baseRecordSchema.merge(
  inventoryItemManufacturerDataV0Schema
);

type InventoryItemManufacturerV0 = z.infer<
  typeof inventoryItemManufacturerV0Schema
>;

const normalizeInventoryItemManufacturerV0 = (
  data: any
): InventoryItemManufacturer => {
  try {
    const inventoryItemManufacturerV0: InventoryItemManufacturerV0 =
      inventoryItemManufacturerV0Schema.parse(data);

    const { ...rest } = inventoryItemManufacturerV0;

    // here it's same object cause current v is 0
    const inventoryItemManufacturer: InventoryItemManufacturer = {
      ...rest,
    };
    return inventoryItemManufacturer;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const INVENTORY_ITEM_MANUFACTURER_VERSION = "0";

export type InventoryItemManufacturerData = InventoryItemManufacturerDataV0;

export type InventoryItemManufacturer = BaseRecord &
  InventoryItemManufacturerData;

export const inventoryItemManufacturerDataSchema =
  inventoryItemManufacturerDataV0Schema;

export const inventoryItemManufacturerSchema =
  inventoryItemManufacturerV0Schema;

export const normalizeInventoryItemManufacturer = (
  data: any
): InventoryItemManufacturer => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeInventoryItemManufacturerV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
