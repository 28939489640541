import { z } from "zod";

// t("INVENTORY_COUNT")
// t("INVENTORY_SURPLUS")
// t("INVENTORY_DEFICIT")
// t("RETURN_CUSTOMER")
// t("RETURN_SUPPLIER")
// t("PRODUCT_ADMINISTRED")
// t("PRODUCT_DEADMINISTRED")
// t("PURCHASE")
// t("INVOICE")
// t("ADJUSTMENT")
// t("DAMAGED")
// t("EXPIRED")
// t("STOLEN")
// t("OTHER")

export const inventoryTransactionTypeEnum = z.enum([
  "INVENTORY_COUNT",
  "INVENTORY_SURPLUS",
  "INVENTORY_DEFICIT",
  "RETURN_CUSTOMER",
  "RETURN_SUPPLIER",
  "PRODUCT_ADMINISTRED",
  "PRODUCT_DEADMINISTRED",
  "PURCHASE",
  "INVOICE",
  "ADJUSTMENT",
  "DAMAGED",
  "EXPIRED",
  "STOLEN",
  "OTHER",
]);
export type InventoryTransactionType = z.infer<
  typeof inventoryTransactionTypeEnum
>;
