import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export interface AppState {
  language: string;
}

const initialState: AppState = {
  language: navigator?.language?.toUpperCase().split("-")[0] ?? "FR",
};

export const appSlice = createSlice({
  name: "btr_app",
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
  },
});

export const { setLanguage } = appSlice.actions;

export const selectLanguage = (state: RootState) => state.app.language;

export const appReducer = appSlice.reducer;
