import { Carousel } from "@mantine/carousel";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Image,
  Space,
  Stack,
  Text,
  Title,
  createStyles,
  rem,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconQuote } from "@tabler/icons-react";
import { selectLanguage } from "app/App.slice";
import { useAppSelector } from "app/hooks";
import {
  Landing1IMG,
  LandingAutomationIMG,
  LandingAutomationIMG_EN,
  LandingBelieve3IMG,
  LandingDesignIMG,
  LandingDesignIMG_EN,
  LandingInventoryIMG,
  LandingInventoryIMG_EN,
  LandingOverviewIMG,
  LandingOverviewIMG_EN,
  LandingReminderSMSIMG,
  LandingReminderSMSIMG_EN,
  LandingTestimonial_1_1IMG,
  LandingTestimonial_2_1IMG,
  LandingTestimonial_3_1IMG,
  LandingTestimonial_4_1IMG,
  LandingWhy1IMG,
  LandingWhy2IMG,
  LandingWhy3IMG,
} from "assets";
import Autoplay from "embla-carousel-autoplay";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LostRevenueCalculator } from "./LostRevenueCalculator";
import WistiaVideo from "./WistiaVideo";

const useStyles = createStyles((theme) => ({
  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.colors.dark[4],
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(40),
    lineHeight: 1.1,
    fontWeight: 600,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },

  highlight: {
    position: "relative",
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: theme.primaryColor,
    }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(2)} ${rem(12)}`,
  },
}));

// sauce: https://docs.google.com/forms/d/e/1FAIpQLSdCrOanY-DQOwC8aXeFRsGZBqoVxrTdqh2I4f29i9i2DLicBA/viewform?usp=sf_link
export const Home = () => {
  //const formLink = `https://docs.google.com/forms/d/e/1FAIpQLSdCrOanY-DQOwC8aXeFRsGZBqoVxrTdqh2I4f29i9i2DLicBA/formResponse?entry.1235242366=`;
  const { classes } = useStyles();
  //const theme = useMantineTheme();
  const navigate = useNavigate();
  const autoplay = useRef(Autoplay({ delay: 5000 }));

  const { t } = useTranslation();

  const largerThanSM = useMediaQuery("(min-width: 48em)");

  const language = useAppSelector(selectLanguage);

  const getLandingOverviewIMGByLanguage = (): string => {
    switch (language) {
      case "EN":
        return LandingOverviewIMG_EN;
      case "FR":
        return LandingOverviewIMG;
      default:
        return LandingOverviewIMG;
    }
  };

  const getLandingAutomationIMGByLanguage = (): string => {
    switch (language) {
      case "EN":
        return LandingAutomationIMG_EN;
      case "FR":
        return LandingAutomationIMG;
      default:
        return LandingAutomationIMG;
    }
  };

  const getLandingDesignIMGByLanguage = (): string => {
    switch (language) {
      case "EN":
        return LandingDesignIMG_EN;
      case "FR":
        return LandingDesignIMG;
      default:
        return LandingDesignIMG;
    }
  };

  const getLandingInventoryIMGByLanguage = (): string => {
    switch (language) {
      case "EN":
        return LandingInventoryIMG_EN;
      case "FR":
        return LandingInventoryIMG;
      default:
        return LandingInventoryIMG;
    }
  };
  const getLandingReminderSMSIMGByLanguage = (): string => {
    switch (language) {
      case "EN":
        return LandingReminderSMSIMG_EN;
      case "FR":
        return LandingReminderSMSIMG;
      default:
        return LandingReminderSMSIMG;
    }
  };

  // const [submitted, setSubmitted] = useState(false);
  // const form = useForm({
  //   initialValues: {
  //     email: "",
  //   },

  //   validate: {
  //     email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
  //   },
  // });

  // const submit = async (email: string) => {
  //   await fetch(`${formLink}${email}`, { mode: "no-cors" });
  //   console.log("done");
  //   setSubmitted(true);
  // };

  const DividerGroup = (
    <>
      <Space h={80} />
      <Divider m="xl" w="90%" />
      <Space h={80} />
    </>
  );

  return (
    <>
      <Container size="xl" mt="xl" pt="xs">
        <Stack spacing={0} justify="center" align="center">
          <Flex
            direction={{ base: "column-reverse", sm: "row" }}
            gap={{ base: "xl", sm: "xl" }}
            align="flex-start"
            mb="xl"
            mt="xl"
          >
            <Box p="lg">
              <Title className={classes.title} mb="xl" mr="xl" mt="xl">
                {t("Simplifiez la gestion de votre pratique vétérinaire")}
              </Title>
              <Space h="xs" />
              <Text color="dimmed" mt="sm" mb="xl" size={20}>
                {t(
                  "Beitary est une plateforme de gestion de pratique vétérinaire basée sur le cloud, intuitive, et qui vous aide à ramener plus d'histoires à la maison et moins de stress."
                )}
              </Text>
              <Button
                radius="xl"
                size="lg"
                mt="sm"
                onClick={() => navigate("/schedule-demo")}
              >
                {t("Réservez une démo")}
              </Button>
            </Box>

            {largerThanSM && <Space w="xl" h="xl" m="xl" />}
            <Box miw={"50%"} ml="xl" pl="xs">
              <Image src={Landing1IMG} />
            </Box>
          </Flex>
          {largerThanSM && <Space w="xl" h="xl" m="xl" />}
          <Box miw={300} w={"60vw"} mt="xl">
            <WistiaVideo />
          </Box>
          {DividerGroup}
          <Title size={28} color="cyan.7" mb="xl" mr="xl" align="center">
            {t(
              "Un flux de travail simple qui correspond à votre façon de travailler"
            )}
          </Title>
          <Text mb="xl" size={26} align="center">
            {t("(car il a été conçu par des vétérinaires comme vous)")}
          </Text>
          <Space h="xl" />
          <Flex
            direction={{ base: "column", sm: "row" }}
            gap={{ base: "xl", sm: "xl" }}
            align="flex-start"
            mb="xl"
            mt="xl"
          >
            <Box miw={"50%"} ml="xl" pl="xs">
              <Image src={getLandingOverviewIMGByLanguage()} />
            </Box>
            {largerThanSM && <Space w="4%" h="xl" m="xl" />}
            <Box p="xl">
              <Text color="dark" mt="sm" mb="xl" size={21}>
                {t(
                  "Les logiciels traditionnels de gestion de clinique vétérinaire créent une complexité, des frictions et des charges inutiles."
                )}
              </Text>
              <Space h="xs" />
              <Text color="dark" mt="sm" mb="xl" size={21}>
                {t(
                  "Le logiciel vétérinaire Beitary simplifie la gestion de votre clinique vétérinaire, vous offrant ainsi plus de temps, des équipes plus épanouies et plus d'opportunités pour fournir les meilleurs soins."
                )}
              </Text>
            </Box>
            {largerThanSM && <Space w="4%" h="xl" m="xl" />}
          </Flex>
          {DividerGroup}
          <Title
            size={28}
            color="cyan.7"
            mb="xl"
            mr="xl"
            mt="xl"
            align="center"
          >
            {t(
              "Logiciel basé sur le cloud conçu spécifiquement pour les vétérinaires"
            )}
          </Title>
          <Space h="sm" m="xl" />
          <Flex
            direction={{ base: "column", sm: "row" }}
            gap={{ base: "xl", sm: "xl" }}
            align="flex-start"
            mt="xl"
            px="xl"
          >
            <Stack miw={"23%"} p="xs" align="center" spacing={0}>
              <Image src={LandingWhy1IMG} />
              <Space h="xl" m="xl" />
              <Text color="cyan" mt="sm" mb="xl" size={24} align="center">
                {t("Efficacité amélioré")}
              </Text>
              <Space h="xs" />
              <Text color="dark" mt="sm" mb="xl" size={16} align="center">
                {t(
                  "L'administration des traitements les ajoute automatiquement à une facture et génère les notes de compte-rendu."
                )}
              </Text>
            </Stack>
            <Space h="xl" m="xl" w="xl" />
            <Stack miw={"23%"} p="xs" align="center" spacing={0}>
              <Image src={LandingWhy2IMG} />
              <Space h="xl" m="xl" />
              <Text color="cyan" mt="sm" mb="xl" size={24} align="center">
                {t("Facile à utiliser")}
              </Text>
              <Space h="xs" w="xl" />
              <Text color="dark" mt="sm" mb="xl" size={16} align="center">
                {t(
                  "Avec une interface intuitive , vous maîtriserez Beitary dès le premier jour et l'adorerez chaque jour après."
                )}
              </Text>
            </Stack>
            <Space h="xl" w="xl" m="xl" />
            <Stack miw={"23%"} p="xs" align="center" spacing={0}>
              <Image src={LandingWhy3IMG} />
              <Space h="xl" m="xl" />
              <Text color="cyan" mt="sm" mb="xl" size={24} align="center">
                {t("Support supplémentaire")}
              </Text>
              <Space h="xs" />
              <Text color="dark" mt="sm" mb="xl" size={16} align="center">
                {t(
                  "Beitary est comme avoir un membre d'équipe supplémentaire qui vous aide à fournir les meilleurs soins, vous permettant ainsi de ne plus emporter de paperasse à la maison chaque soir."
                )}
              </Text>
            </Stack>
          </Flex>
          {DividerGroup}
          <Carousel
            w="90vw"
            slideSize="90%"
            height="auto"
            slideGap={"10%"}
            controlsOffset="xs"
            loop
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
          >
            <Carousel.Slide>
              <Flex
                direction={{ base: "column", sm: "row" }}
                gap={{ base: 0, sm: "xl" }}
                align="center"
                m={largerThanSM ? "xl" : 0}
                p={largerThanSM ? "xl" : 0}
              >
                <Image
                  src={LandingTestimonial_1_1IMG}
                  fit="contain"
                  maw={"70%"}
                  miw={"30%"}
                />
                <Space w="xl" />
                <Box miw={"65%"} maw={"80%"}>
                  <IconQuote
                    size={largerThanSM ? 40 : 26}
                    stroke={3}
                    color="cyan"
                    style={{
                      transform: "rotate(180deg)",
                    }}
                  />
                  <Text color="cyan" mb="sm" size={largerThanSM ? 28 : 18}>
                    {t(
                      "Nous sommes extrêmement satisfaits de Beitary. C'est tellement plus rapide que notre ancien système. Nous recommandons Beitary à tous les vétos !"
                    )}
                  </Text>
                  <Space h="xs" />
                  <Text color="dark" mt="sm" size={16}>
                    {t("Dr Sahar Shili")}
                  </Text>
                  <Text color="dark" size={16}>
                    {t("Pets’Doctor Ennasr 2")}
                  </Text>
                </Box>
              </Flex>
            </Carousel.Slide>
            <Carousel.Slide>
              <Flex
                direction={{ base: "column", sm: "row" }}
                gap={{ base: 0, sm: "xl" }}
                align="center"
                m={largerThanSM ? "xl" : 0}
                p={largerThanSM ? "xl" : 0}
              >
                <Image
                  src={LandingTestimonial_2_1IMG}
                  fit="contain"
                  maw={"70%"}
                  miw={"30%"}
                />
                <Space w="xl" />
                <Box miw={"65%"} maw={"80%"}>
                  <IconQuote
                    size={largerThanSM ? 40 : 26}
                    stroke={3}
                    color="cyan"
                    style={{
                      transform: "rotate(180deg)",
                    }}
                  />
                  <Text color="cyan" mb="sm" size={largerThanSM ? 28 : 18}>
                    {t(
                      "Grâce à Beitary, notre équipe a gagné en efficacité et en productivité. Le passage du papier à la plateforme numérique a été sans faille et l'équipe de support technique a été incroyablement réactive. Nous sommes ravis d'avoir choisi Beitary !"
                    )}
                  </Text>
                  <Space h="xs" />
                  <Text color="dark" mt="sm" size={16}>
                    {t("Dr Laila Ben Hadj Hmida, Dr Youssef Tarhouni")}
                  </Text>
                  <Text color="dark" size={16}>
                    {t("Clinique Vétérinaire Rades")}
                  </Text>
                </Box>
              </Flex>
            </Carousel.Slide>
            <Carousel.Slide>
              <Flex
                direction={{ base: "column", sm: "row" }}
                gap={{ base: 0, sm: "xl" }}
                align="center"
                m={largerThanSM ? "xl" : 0}
                p={largerThanSM ? "xl" : 0}
              >
                <Image
                  src={LandingTestimonial_3_1IMG}
                  fit="contain"
                  maw={"70%"}
                  miw={"30%"}
                />
                <Space w="xl" />
                <Box miw={"65%"} maw={"80%"}>
                  <IconQuote
                    size={largerThanSM ? 40 : 26}
                    stroke={3}
                    color="cyan"
                    style={{
                      transform: "rotate(180deg)",
                    }}
                  />
                  <Text color="cyan" mb="sm" size={largerThanSM ? 28 : 18}>
                    {t(
                      "Les rappels automatiques de Beitary sont très appréciés par nos clients. Ils n'ont plus à se soucier des dates de vaccination ou des rendez-vous. Cela a considérablement réduit les absences et amélioré la satisfaction client."
                    )}
                  </Text>
                  <Space h="xs" />
                  <Text color="dark" mt="sm" size={16}>
                    {t("Dr Hbib Kammoun")}
                  </Text>
                  <Text color="dark" size={16}>
                    {t("Clinique Vétérinaire Animalia")}
                  </Text>
                </Box>
              </Flex>
            </Carousel.Slide>
            <Carousel.Slide>
              <Flex
                direction={{ base: "column", sm: "row" }}
                gap={{ base: 0, sm: "xl" }}
                align="center"
                m={largerThanSM ? "xl" : 0}
                p={largerThanSM ? "xl" : 0}
              >
                <Image
                  src={LandingTestimonial_4_1IMG}
                  fit="contain"
                  maw={"70%"}
                  miw={"30%"}
                />
                <Space w="xl" />
                <Box miw={"65%"} maw={"80%"}>
                  <IconQuote
                    size={largerThanSM ? 40 : 26}
                    stroke={3}
                    color="cyan"
                    style={{
                      transform: "rotate(180deg)",
                    }}
                  />
                  <Text color="cyan" mb="sm" size={largerThanSM ? 28 : 18}>
                    {t(
                      "Avec Beitary, organiser nos rendez-vous est simple et efficace. Le calendrier coloré nous aide à visualiser rapidement notre journée. Nos clients adorent recevoir les notifications, ce qui a grandement réduit les rendez-vous manqués."
                    )}
                  </Text>
                  <Space h="xs" />
                  <Text color="dark" mt="sm" size={16}>
                    {t("Dr Olfa Kilani")}
                  </Text>
                  <Text color="dark" size={16}>
                    {t("Pawradise - Clinique Vétérinaire les berges du Lac 2")}
                  </Text>
                </Box>
              </Flex>
            </Carousel.Slide>
          </Carousel>
          {DividerGroup}
          <Title
            size={28}
            color="cyan.7"
            mb="xl"
            mr="xl"
            mt="xl"
            align="center"
          >
            {t(
              "Des fonctionnalités robustes qui simplifient la gestion de votre cabinet vétérinaire"
            )}
          </Title>
          <Space h={50} />
          <Flex
            direction={{ base: "column", sm: "row" }}
            gap={{ base: "xl", sm: "xl" }}
            align="flex-start"
            mb="xl"
            mt="xl"
          >
            <Box miw={"40%"} ml={largerThanSM ? "xl" : 0} pl="xs">
              <Image src={getLandingDesignIMGByLanguage()} />
            </Box>
            {largerThanSM && <Space w="4%" h="xl" m="xl" />}
            <Box p="xl">
              <Text color="cyan" mt="sm" size={24}>
                {t("Conçu pour votre flux de travail")}
              </Text>
              <Text color="dark" mt="sm" mb="xl" size={22}>
                {t(
                  "Beitary n'est pas seulement beau - il est conçu pour fonctionner selon votre manière de travailler et la façon dont vous traitez les patients lors d'une visite."
                )}
              </Text>
            </Box>
            <Space w="4%" h="xl" m="xl" />
          </Flex>
          {largerThanSM && <Space h={140} />}
          <Flex
            direction={{ base: "column", sm: "row" }}
            gap={{ base: "xl", sm: "xl" }}
            align="flex-start"
            mb="xl"
            mt="xl"
          >
            <Box miw={"40%"} ml={largerThanSM ? "xl" : 0} pl="xs">
              <Image src={getLandingInventoryIMGByLanguage()} />
            </Box>
            {largerThanSM && <Space w="4%" h="xl" m="xl" />}
            <Box p="xl">
              <Text color="cyan" mt="sm" size={24}>
                {t("Suivi d'inventaire sans effort:")}{" "}
              </Text>
              <Text color="dark" mt="sm" mb="xl" size={22}>
                {t(
                  "Suivez instantanément le stock avec chaque administration de produit et commandez facilement de nouveaux approvisionnements."
                )}
              </Text>
            </Box>
            <Space w="4%" h="xl" m="xl" />
          </Flex>
          {largerThanSM && <Space h={140} />}
          <Flex
            direction={{ base: "column", sm: "row" }}
            gap={{ base: "xl", sm: "xl" }}
            align="flex-start"
            mb="xl"
            mt="xl"
          >
            <Box miw={"40%"} ml={largerThanSM ? "xl" : 0} pl="xs">
              <Image src={getLandingAutomationIMGByLanguage()} />
            </Box>
            {largerThanSM && <Space w="4%" h="xl" m="xl" />}
            <Box p="xl">
              <Text color="cyan" mt="sm" size={24}>
                {t("Outils d'automatisation")}
              </Text>
              <Text color="dark" mt="sm" mb="xl" size={22}>
                {t(
                  "Saisissez les informations du patient une fois et elles se rempliront automatiquement partout où c'est nécessaire, y compris la saisie de toutes les charges. Si votre dossier médical est précis, votre facture le sera automatiquement aussi."
                )}
              </Text>
            </Box>
            <Space w="4%" h="xl" m="xl" />
          </Flex>
          {largerThanSM && <Space h={140} />}
          <Flex
            direction={{ base: "column", sm: "row" }}
            gap={{ base: "xl", sm: "xl" }}
            align="flex-start"
            mb="xl"
            mt="xl"
          >
            <Box miw={"40%"} ml={largerThanSM ? "xl" : 0} pl="xs">
              <Image src={getLandingReminderSMSIMGByLanguage()} />
            </Box>
            {largerThanSM && <Space w="4%" h="xl" m="xl" />}
            <Box p="xl">
              <Text color="cyan" mt="sm" size={24}>
                {t("Rappels personnalisables")}
              </Text>
              <Text color="dark" mt="sm" mb="xl" size={22}>
                {t(
                  "Améliorez l'observance des patients et gagnez du temps pour votre équipe grâce à des rappels automatiques par SMS et e-mail personnalisables. Vous réduirez les rendez-vous manqués, et les propriétaires d'animaux resteront heureusement informés des calendriers de vaccination et des suivis post-opératoires."
                )}
              </Text>
            </Box>
            {largerThanSM && <Space w="4%" h="xl" m="xl" />}
          </Flex>

          {DividerGroup}
          <Box p={largerThanSM ? "xl" : "xs"} mx={largerThanSM ? "xl" : "xs"}>
            <Text color="cyan" size={largerThanSM ? 30 : 24}>
              {t(
                "L'automatisation des factures élimine les charges manquées et améliore votre revenu net"
              )}
            </Text>
            <Text color="dark" mt="sm" mb="xl" size={largerThanSM ? 21 : 18}>
              {t(
                "Des études montrent que les cabinets vétérinaires perdent jusqu'à 10% de leurs revenus en raison des charges oubliées. C'est de l'argent que vous auriez pu gagner."
              )}
            </Text>
            <Text color="dark" mt="sm" mb="xl" size={largerThanSM ? 21 : 18}>
              {t("Calculez les revenus que vous pourriez manquer:")}
            </Text>
            <LostRevenueCalculator />
            <Space h="xl" />
            <Text color="dark" mt="xl" mb="xl" size={largerThanSM ? 21 : 18}>
              {t(
                "Avec Beitary, vous capturez automatiquement toutes les charges. Si elles sont sur le dossier médical, elles sont sur la facture. Aucun élément n'est oublié - transformant les pertes en gains."
              )}
            </Text>
          </Box>
          {DividerGroup}
          <Flex
            direction={{ base: "column", sm: "row" }}
            gap={{ base: "xl", sm: "xl" }}
            align="flex-start"
            mb="xl"
            mt="xl"
          >
            <Box p={largerThanSM ? "xl" : "xs"} ml={largerThanSM ? "xl" : "xs"}>
              <Text color="cyan" size={40}>
                {t("Ce que nous croyons")}
              </Text>
              <Text color="dark" mt="sm" mb="xl" size={21}>
                {t(
                  "Parce que Beitary est né de la conviction que les vétérinaires méritent la joie, il a été conçu pour fonctionner de la manière dont les vétérinaires travaillent et rendre ce travail un peu facile."
                )}
              </Text>
              <Text color="dark" mt="sm" mb="xl" size={21}>
                {t(
                  "Nous sommes en mission pour simplifier la gestion vétérinaire afin que vous puissiez raviver votre joie et diriger des équipes plus épanouies."
                )}
              </Text>
            </Box>
            {largerThanSM ? <Space w="4%" h="xl" m="xl" /> : <></>}
            <Box
              miw={"35%"}
              ml={largerThanSM ? "xl" : 0}
              pt={largerThanSM ? "xl" : "xs"}
              mt={largerThanSM ? "xl" : 0}
            >
              <Image src={LandingBelieve3IMG} />
            </Box>
            <Space w="4%" h="xl" m="xl" />
          </Flex>
          {/* <List
            mt={30}
            spacing="sm"
            size="sm"
            icon={
              <ThemeIcon color="cyan" size={20} radius="xl">
                <IconCheck size={rem(12)} stroke={1.5} />
              </ThemeIcon>
            }
          >
            <List.Item>
              <b>Rendez-vous et Rappels automatiques</b> – planifiez et gérez
              les rendez-vous pour vos patients, avec des rappels automatiques!
            </List.Item>
            <List.Item>
              <b>Dossiers médicaux</b> – accédez facilement aux informations
              médicales de vos patients
            </List.Item>
            <List.Item>
              <b>Facturation et gestion des paiements</b> – gérez efficacement
              les finances de votre pratique
            </List.Item>
            <List.Item>Et plein d'autres fonctionnalités!</List.Item>
          </List> */}
          {/* <form
              onSubmit={form.onSubmit(async (values) => {
                if (submitted) return;
                console.log(values);
                await submit(values.email);
              })}
            >
              <Group mt={30}>
                {!submitted && (
                  <TextInput
                    radius="xl"
                    size="md"
                    withAsterisk
                    placeholder="your@email.com"
                    {...form.getInputProps("email")}
                  />
                )}

                <Button
                  radius="xl"
                  size="md"
                  className={classes.control}
                  color="cyan"
                  type="submit"
                >
                  {submitted ? "Merci!" : "Abonnez-vous!"}
                </Button>
              </Group>{" "}
            </form> */}
          <Button
            radius="xl"
            size="lg"
            m="xl"
            onClick={() => navigate("/schedule-demo")}
          >
            {t("Réservez une démo")}
          </Button>
          <Space h={30} m="xl" />
          {/* <Group position="center" p="xs">
            <Group
              mt="xl"
              position="apart"
              p="xl"
              w="70%"
              sx={{
                backgroundColor: theme.colors.cyan[0],
                borderRadius: theme.radius.xl,
              }}
            >
              <Box>
                <Text size={26} weight="bold">
                  Réservez une démo
                </Text>
                <Text size={26} weight="bold" color="red.5" bg="teal.0">
                  au +216 56 103 345
                </Text>
              </Box>
              <Box w={160}>
                <Image height={160} width={160} src={WhatsappImg} />
              </Box>
            </Group>
          </Group> */}
        </Stack>
      </Container>
    </>
  );
};
