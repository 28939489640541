import {
  Box,
  Button,
  Center,
  Container,
  Group,
  Image,
  Space,
  Stack,
  Text,
  TextInput,
  Textarea,
  ThemeIcon,
} from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { IconMail, IconPhone } from "@tabler/icons-react";
import { WhatsappCyanImg } from "assets";
import axios from "axios";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { z } from "zod";

export const Contact = () => {
  const { t } = useTranslation();
  ///////////////////////
  const formSchema = z.object({
    email: z.string().email(),
    content: z.string(),
    name: z.string(),
  });

  type Submission = {
    subject: string;
    html: string;
  };

  type FormValues = z.infer<typeof formSchema>;

  const rules = zodResolver(formSchema);

  ///////////////////
  const [submitState, setSubmitState] = useSubmitState();

  const form = useForm<FormValues>({
    validate: rules,
  });

  const submit = async (values: FormValues) => {
    const url =
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1" ||
      window.location.hostname === ""
        ? "http://127.0.0.1:5001/beitary-app-dev/europe-west1/api"
        : "https://europe-west1-beitary-app-prod.cloudfunctions.net/api"; // !!!! TODO?? fix this to load url from config file

    const api = axios.create({
      headers: {
        "content-type": "application/json",
      },
    });
    api.defaults.baseURL = url;

    const data: Submission = {
      subject: "Beitary.com - Contact",
      html: `<p>${values.name}</p><p>${values.content}</p>`,
    };

    setSubmitState("pending-response");
    try {
      const result = await api.post(`/emailAdmin`, data);
      console.log(result);
    } catch (error) {
      console.log(error);
    }

    setSubmitState("success");
  };

  return (
    <Container mt="xl" pt="xl">
      <Text color="cyan" size={40} align="center">
        {t("Restons connectés !")}
      </Text>
      <Text align="center" my="xl" size={20}>
        {t(
          "Suivez-nous pour obtenir plus d'informations sur l'industrie, un aperçu en coulisses de notre équipe, et du contenu vraiment mignon avec des animaux !"
        )}
      </Text>
      <Group position="center" my={50}>
        <Button
          radius="xl"
          size="lg"
          w={200}
          component="a"
          href="https://www.facebook.com/beitary"
          target="_blank"
        >
          {t("Facebook")}
        </Button>
        <Button
          radius="xl"
          size="lg"
          w={200}
          component="a"
          href="https://www.youtube.com/@beitary"
          target="_blank"
        >
          {t("Youtube")}
        </Button>
        <Button
          radius="xl"
          size="lg"
          w={200}
          component="a"
          href="https://wa.me/+21656103345?text=Beitary.com"
          target="_blank"
        >
          {t("Whatsapp")}
        </Button>
      </Group>
      <Space h="xl" />
      <Center mb="xl">
        <Group spacing="xl" align="center">
          <Group>
            <ThemeIcon variant="light" size="xl">
              <IconMail />
            </ThemeIcon>
            <Text
              color="cyan"
              size="xl"
              component="a"
              href={`mailto:${"contact@beitary.com"}`}
            >
              {"contact@beitary.com"}
            </Text>
          </Group>
          <Group>
            <ThemeIcon variant="light" size="xl">
              <IconPhone />
            </ThemeIcon>
            <Text
              color="cyan"
              size="xl"
              component="a"
              href={`tel:+21656103345`}
            >
              +216 56 103 345
            </Text>
          </Group>
        </Group>
      </Center>
      <Space h="xl" />
      <Center mb="xl">
        <Image src={WhatsappCyanImg} width={150} h={150} fit="scale-down" />
      </Center>
      <Space h={140} />
      <Text color="cyan" size={40} align="center">
        {t("Des questions ? Nous avons des réponses !")}
      </Text>
      <Text align="center" my="xl" size={20}>
        {t(
          "Que vous soyez curieux de nos services, que vous ayez besoin d'aide ou que vous vouliez simplement dire bonjour, nous sommes là pour vous aider."
        )}
      </Text>
      <Space h={60} />
      <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
        <Stack>
          <Box>
            <Stack p="xl">
              <TextInput
                required
                label={t("Nom")}
                {...form.getInputProps("name")}
              />
              <TextInput
                required
                label={t("Email")}
                {...form.getInputProps("email")}
              />
              <Textarea
                required
                label={t("Message")}
                {...form.getInputProps("content")}
              />
            </Stack>
          </Box>
          {submitState !== "success" && (
            <Group position="right">
              <Button
                type="submit"
                loading={submitState === "pending-response"}
                color={form.isValid() ? "cyan" : "gray"}
              >
                {t("Soumettre")}
              </Button>
            </Group>
          )}
          {submitState === "success" && (
            <Text align="center" my="xl" color="cyan.9" size={16}>
              {t(
                "Nous vous remercions de nous avoir contactés ! Nous traiterons votre demande dès que possible."
              )}
            </Text>
          )}
          <Space h={60} />
        </Stack>
      </form>
    </Container>
  );
};
