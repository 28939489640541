import { z } from "zod";

// default sharing after //
export const bFileCategoriesEnum = z.enum([
  "ADDENDUM_FILES", // private with option
  "LAB_REPORT", // shared client - orgs
  "IMAGING", // shared client - orgs
  "DOC_FILE", // private with option
  "GENERIC_PATIENT_FILE", // private with option
  "INVOICE", // shared client
  "PAYMENT_RECEIPT", // shared client
  "VACCINE_CERTIFICATE", // shared client - orgs
  "PRESCRIPTION", // shared client - orgs
  "DISCHARGE_INSTRUCTIONS", // shared client
  "MEDICAL_EXAM_REPORT", // shared client - orgs
  "REMINDERS_SUMMARY", // shared client
]);
export type BFileCategory = z.infer<typeof bFileCategoriesEnum>;
