import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { genericStatusEnum } from "../../common/status/GenericStatus";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
// import { productConcentrationSoluteUnitTypeEnum } from "../../common/units/ProductConcentrationSoluteUnit/ProductConcentrationSoluteUnit";
import {
  dueTimeOptionsEnum,
  productConcentrationSolventUnitTypeEnum,
  productDoseBaseEnum,
} from "../../common";
import { productAdministrationRouteEnum } from "../../common/units/ProductAdministrationRoutes/ProductAdministrationRoutes";
import { productTypeEnum } from "../ProductType/ProductType";

/**
 * schema and types versions
 * from first to last
 */

// v0

const productDataV0Schema = z.object({
  status: genericStatusEnum,

  name: z.string(),
  code: z.string().optional(),
  type: productTypeEnum,
  notes: z.string().optional(),
  categoryId: z.string(), // category id is same as name?

  sellingPrice: z.number().int().nonnegative(),
  minPrice: z.number().int().nonnegative(),
  dispFees: z.number().int().nonnegative(),

  //
  //serialNumber: z.string().optional(),
  lotNumber: z.string().optional(),
  lotExpirationDate: z.number().int().positive().optional(),

  //
  controlled: z.boolean().optional(),
  discountable: z.boolean().optional(),
  taxable: z.boolean().optional(),
  includeInMR: z.boolean().optional(),
  hasMRN: z.boolean().optional(),
  mrNotes: z.string().optional(), // https://intercom.help/shepherd-20/en/articles/5008621-automatic-product-and-service-notes
  printOnInvoice: z.boolean().optional(), // otherwise add it to "other charges"? kima compress mathalan ma t7ebbehech fel invoice ka compress
  hasDischInstr: z.boolean().optional(),
  dischInstr: z.string().optional(),
  hasPrespDir: z.boolean().optional(),
  prespDir: z.string().optional(),

  //
  hasLII: z.boolean().optional(),
  linkedInventoryItemId: z.string().optional(),
  linkedInventoryItemName: z.string().optional(),
  unitRatio: z.number().positive().optional(),
  //
  // ccAmount: z.number().positive().optional(),
  // ccSoluteUnit: productConcentrationSoluteUnitTypeEnum.optional(),
  ccSolventUnit: productConcentrationSolventUnitTypeEnum.optional(),
  dose: z.number().positive().optional(),
  doseBase: productDoseBaseEnum.optional(),
  adminRoutes: z.array(productAdministrationRouteEnum).optional(),
  adminInstr: z.string().optional(),

  //
  hasTask: z.boolean().optional(),
  taskName: z.string().optional(),
  taskDueDate: z.number().positive().optional(),
  taskPeriod: dueTimeOptionsEnum.optional(),

  //
  hasPatStat: z.boolean().optional(),
  patStat: z.enum(["STERILE", "DECEASED"]).optional(),
});

// TODO add reminder on product/service admin
// TODO add task on product/service admin https://intercom.help/shepherd-20/en/articles/4487422-products-and-services-overview

type ProductDataV0 = z.infer<typeof productDataV0Schema>;

const productV0Schema = baseRecordSchema.merge(productDataV0Schema);

type ProductV0 = z.infer<typeof productV0Schema>;

const normalizeProductV0 = (data: any): Product => {
  try {
    const productV0: ProductV0 = productV0Schema.parse(data);

    const { ...rest } = productV0;

    // here it's same object cause current v is 0
    const product: Product = {
      ...rest,
    };
    return product;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const PRODUCT_VERSION = "0";

export type ProductData = ProductDataV0;

export type Product = BaseRecord & ProductData;

export const productDataSchema = productDataV0Schema;

export const productSchema = productV0Schema;

export const normalizeProduct = (data: any): Product => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeProductV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
