import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

const consultationNoteDataV0Schema = z.object({
  content: z.string(),
  parentType: z.enum(["TREATMENT", "CONSULTATION", "PLAN"]),
  parentId: z.string(), // could be consultation, treatment, plan...
});

type ConsultationNoteDataV0 = z.infer<typeof consultationNoteDataV0Schema>;

const consultationNoteV0Schema = baseRecordSchema.merge(
  consultationNoteDataV0Schema
);

type ConsultationNoteV0 = z.infer<typeof consultationNoteV0Schema>;

const normalizeConsultationNoteV0 = (data: any): ConsultationNote => {
  try {
    const consultationNoteV0: ConsultationNoteV0 =
      consultationNoteV0Schema.parse(data);

    const { ...rest } = consultationNoteV0;

    // here it's same object cause current v is 0
    const consultationNote: ConsultationNote = {
      ...rest,
    };
    return consultationNote;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const CONSULTATION_NOTE_VERSION = "0";

export type ConsultationNoteData = ConsultationNoteDataV0;

export type ConsultationNote = BaseRecord & ConsultationNoteData;

export const consultationNoteDataSchema = consultationNoteDataV0Schema;

export const consultationNoteSchema = consultationNoteV0Schema;

export const normalizeConsultationNote = (data: any): ConsultationNote => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeConsultationNoteV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
