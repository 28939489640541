import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { patientExternalIdTypeEnum } from "../PatientExternalIdType";
import { patientSexEnum } from "../PatientSex";
import { patientSpeciesEnum } from "../PatientSpecies";
import { patientStatusEnum } from "../PatientStatus";

/**
 * schema and types versions
 * from first to last
 */

// v0

const patientDataV0Schema = z.object({
  status: patientStatusEnum, // TODO: update stuff based on this
  ownerId: z.string(),
  ownerName: z.string(),
  name: z.string(),
  externalIDs: z
    .array(
      z.object({
        type: patientExternalIdTypeEnum,
        description: z.string(),
        id: z.string(),
      })
    )
    .optional(),
  dateOfBirth: z.number().positive().int(),
  dateOfDeath: z.number().positive().int().optional(), // TODO validate that it's after dateOfBirth?
  sex: patientSexEnum,
  species: patientSpeciesEnum,
  breed: z.string().optional(),
  color: z.string().optional(),
  weight: z.number().optional(),
  pictureURL: z.string().url().optional(),
  alert: z.string().optional(),
  deceased: z.boolean().optional(),
  // TODO add chronicConditnons and chronicMedication??
  // see https://github.com/Beitary/beitary_app_web/issues/247
});

type PatientDataV0 = z.infer<typeof patientDataV0Schema>;

const patientV0Schema = baseRecordSchema.merge(patientDataV0Schema);

type PatientV0 = z.infer<typeof patientV0Schema>;

const normalizePatientV0 = (data: any): Patient => {
  try {
    const patientV0: PatientV0 = patientV0Schema.parse(data);

    const { ...rest } = patientV0;

    // here it's same object cause current v is 0
    const patient: Patient = {
      ...rest,
    };
    return patient;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const PATIENT_VERSION = "0";

export type PatientData = PatientDataV0;

export type Patient = BaseRecord & PatientData;

export const patientDataSchema = patientDataV0Schema;

export const patientSchema = patientV0Schema;

export const normalizePatient = (data: any): Patient => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizePatientV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
