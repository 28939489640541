import { Container, Stack, Text, Title } from "@mantine/core";
import { ReactNode } from "react";

export const TOS = () => {
  const pp: { title: string; content: ReactNode; order: number }[] = [
    {
      title: "1. OBJET",
      content: (
        <p>
          Les présentes Conditions Générales d'Utilisation ont pour but de
          définir les règles d'utilisation des Services de VETALYST et les
          droits et devoirs de VETALYST et des Utilisateurs.
          <br />
          La Plateforme BEITARY (beitary.com) offre aux Utilisateurs la
          possibilité d'utiliser le Service d'Annuaire et de Prise de
          Rendez-Vous en Ligne.
          <br />
          Ces Conditions Générales s'appliquent à toutes les inscriptions sur le
          Site.
          <br />
          VETALYST se réserve le droit de modifier ou compléter ces conditions à
          tout moment. Les modifications seront mises à jour sur le Site.
          <br />
          Il est important de noter que BEITARY n'est pas un service d'urgence.
        </p>
      ),
      order: 1,
    },
    {
      title: "2. DÉFINITION DES TERMES UTILISÉS",
      content: (
        <p>
          Les termes utilisés dans le Contrat ont les significations suivantes :
          <ul>
            <li>
              "Abonné BEITARY" désigne toute personne physique Acteur de Santé
              exerçant à titre libéral, toute personne morale de droit privé
              regroupant des Acteurs de Santé pour l'exercice de leur
              profession, et toute personne de droit public regroupant des
              Acteurs de Santé pour l'exercice de leur profession, bénéficiant
              d'un abonnement sur BEITARY.
            </li>
            <li>
              "Acteur de santé" désigne les professionnels intervenant dans le
              secteur de la santé vétérinaire, accessibles sur la Plateforme
              BEITARY.
            </li>
            <li>
              "Application" désigne la Plateforme BEITARY disponible depuis un
              smartphone par téléchargement depuis un magasin d'applications ou
              sur le site web.
            </li>
            <li>
              "Assistant" désigne tout type de personnel assistant un Acteur de
              santé et effectuant des actions au nom et pour le compte de cet
              Acteur de santé, tels que le personnel administratif, les
              secrétaires médicales, assistants médicaux ou dentaires et
              auxiliaires médicaux.
            </li>
            <li>
              "Compte Utilisateur" désigne l'espace personnel de l'Utilisateur à
              usage privé, lui permettant d'utiliser les Services grâce à ses
              Identifiants.
            </li>
            <li>
              "Contrat" désigne l'ensemble des documents contractuels, y compris
              les présentes Conditions d'Utilisation, les définitions et la
              politique de protection des données à caractère personnel, mis à
              la disposition de l'Utilisateur, notamment lors de la création du
              Compte Utilisateur et à tout moment sur le site www.beitary.com.
            </li>
            <li>
              "Donnée(s) Utilisateur" désigne les données de l'Utilisateur et de
              ses Proches (incluant des Données à caractère personnel)
              mentionnées sur son Compte Utilisateur, partagées avec un Acteur
              de santé ou traitées par VETALYST dans le but de fournir les
              Services, ainsi que tout document téléchargé, diffusé, collecté ou
              mis en ligne par un Utilisateur sur la Plateforme BEITARY.
            </li>
            <li>
              "Données à caractère personnel" désigne toute information
              concernant une personne physique identifiée ou identifiable
              conformément à Loi organique n° 2004-63 du 27 juillet 2004.
            </li>
            <li>
              "Equipement" désigne tout type de matériel permettant l'accès et
              l'utilisation des Services, tels que ordinateurs, casques, micros,
              webcams, téléphones et tablettes.
            </li>
            <li>
              "Identifiant" désigne l'identifiant personnel de connexion associé
              à un Compte Utilisateur ainsi que tout code confidentiel ou mot de
              passe complexe délivré par VETALYST ou créé et modifié par
              l'Utilisateur pour s'identifier et accéder aux Services.
            </li>
            <li>
              "Information Confidentielle" désigne toute information de nature
              financière, juridique, commerciale, technique, informatique ou
              administrative, ainsi que toute autre information qui pourrait
              favoriser les intérêts d'une entreprise concurrente et qui est
              communiquée directement ou indirectement par écrit, oralement ou
              sous toute autre forme et support considéré comme confidentiel en
              raison de sa nature ou des circonstances de sa divulgation. Les
              informations confidentielles ne comprennent pas les informations
              déjà publiques, connues de la Partie destinataire avant leur
              communication ou divulgation, obtenues légalement d'un tiers ou
              autorisées par écrit par l'autre Partie avant leur divulgation.
            </li>
            <li>"Partie(s)" désigne VETALYST et l'Utilisateur.</li>
            <li>
              "Personne concernée" est définie par la Loi organique n° 2004-63
              du 27 juillet 2004.
            </li>
            <li>
              "Plateforme BEITARY" désigne l'ensemble logiciel accessible en
              ligne sur le site www.beitary.com ou mobile accessible via les
              différents "Apps store" du marché, permettant à l'Utilisateur de
              consulter l'annuaire de BEITARY ou de prendre un rendez-vous en
              ligne.
            </li>
            <li>
              "Proche" désigne toute personne physique autorisée par
              l'Utilisateur ou mandatée par la loi à prendre un rendez-vous en
              ligne directement sur son Compte Utilisateur ou à transférer ses
              Données à caractère personnel sur la Plateforme BEITARY.
            </li>
            <li>
              "Propriété Intellectuelle" désigne l'ensemble des droits de
              propriété intellectuelle, y compris les droits d'auteur, les
              droits voisins, le copyright, les dessins et modèles, les
              logiciels et codes sources, les marques, noms commerciaux et
              logos, les noms de domaine et autres signes distinctifs, les bases
              de données, ainsi que les inventions, brevets et savoir-faire, et
              les droits de la personnalité, le droit au respect de la vie
              privée et le droit à l'image.
            </li>
            <li>
              "Responsable du traitement" est défini par la Loi organique n°
              2004-63 du 27 juillet 2004.
            </li>
            <li>
              "Services" désigne le ou les Services mis à disposition des
              Utilisateurs, comprenant notamment le Service d'annuaire et de
              prise de rendez-vous en ligne.
            </li>
            <li>
              "Service d'Annuaire" désigne le service de recherche en ligne
              d'Acteurs de santé proposé par VETALYST, comportant le nom, la
              spécialité, les coordonnées, les informations d'accès, les tarifs,
              les horaires et les expertises des Acteurs de santé.
            </li>
            <li>
              "Service de Prise de Rendez-Vous en Ligne" désigne le service
              permettant à l'Utilisateur de prendre rendez-vous en ligne avec un
              Acteur de santé abonné à BEITARY via le Site ou l'Application
              Mobile.
            </li>
            <li>
              "Signature Électronique" désigne un processus électronique
              permettant à une personne de consentir au contenu d'un document
              électronique en cliquant sur un bouton d'acceptation numérique,
              par exemple.
            </li>
            <li>"Site" désigne le site Internet www.beitary.com.</li>
            <li>
              "Sous-traitant" est défini par la Loi organique n° 2004-63 du 27
              juillet 2004.
            </li>
            <li>
              "Traitement" est défini par la Loi organique n° 2004-63 du 27
              juillet 2004.
            </li>
            <li>
              "Utilisateur" désigne toute personne physique qui dispose d'un
              Compte Utilisateur sur la Plateforme BEITARY et accède aux
              Services via le Site, ainsi que tout Visiteur du Site.
            </li>
            <li>
              "Visiteur du Site" désigne toute personne physique naviguant sur
              le Site www.beitary.com.
            </li>
          </ul>
        </p>
      ),
      order: 1,
    },
    {
      title: "3. CRÉATION D'UN COMPTE UTILISATEUR",
      content: (
        <p>
          Pour accéder au service de prise de rendez-vous et de télé conseil,
          vous devez créer un Compte Utilisateur en ligne. Lors de cette
          création, vous vous engagez à fournir des informations exactes et
          complètes sur votre identité conformément au formulaire en ligne. Vous
          vous engagez à ne pas créer de fausse identité ou usurper l'identité
          d'une autre personne. Vous devez mettre à jour vos données en cas de
          modification ultérieure. VETALYST se réserve le droit de suspendre ou
          de refuser l'accès aux Services en cas d'informations fausses,
          inexactes, obsolètes, incomplètes ou trompeuses fournies par
          l'Utilisateur.
        </p>
      ),
      order: 1,
    },
    {
      title: "4. CONDITIONS D'ACCÈS AUX SERVICES",
      content: (
        <p>
          Vous pouvez accéder au Service d'Annuaire sans créer de compte.
          Cependant, pour accéder aux Services de prise de Rendez-Vous en Ligne,
          vous devez créer un Compte Utilisateur et accepter les présentes CGU.
          En créant votre Compte Utilisateur, vous acceptez les CGU de manière
          expresse. Si vous n'êtes pas d'accord avec les CGU, vous ne devez pas
          utiliser les Services.
        </p>
      ),
      order: 1,
    },
    {
      title: "5. SPÉCIFICITÉS TECHNIQUES D'ACCÈS AUX SERVICES",
      content: (
        <p>
          Vous êtes responsable de la confidentialité de vos Identifiants. En
          cas de perte ou de vol, vous devez informer VETALYST pour annuler ou
          mettre à jour les Identifiants concernés. Vous êtes seul responsable
          des actions effectuées à partir de votre Compte Utilisateur.
        </p>
      ),
      order: 1,
    },
    {
      title: "6. OBLIGATIONS ET RESPONSABILITÉS DE VETALYST",
      content: (
        <p>
          VETALYST s'engage à fournir les Services avec professionnalisme et
          respect des normes en vigueur. VETALYST assure la sécurité et la
          confidentialité des données des Clients. Cependant, VETALYST décline
          toute responsabilité en cas de difficultés d'accès au Site, de
          suspension des Services pour maintenance ou de non-exécution des actes
          médicaux.
        </p>
      ),
      order: 1,
    },
    {
      title:
        "7. SMS, COURRIELS et APPELS TÉLÉPHONIQUES ADRESSÉS À L'UTILISATEUR",
      content: (
        <p>
          En utilisant les Services, vous autorisez VETALYST à vous envoyer des
          SMS, des courriels et des appels téléphoniques pour la confirmation,
          l'annulation ou le rappel de rendez-vous. Vous pouvez vous opposer à
          l'envoi de ces communications.
        </p>
      ),
      order: 1,
    },

    {
      title: "8. OBLIGATIONS GÉNÉRALES DE L'UTILISATEUR",
      content: (
        <p>
          Vous êtes responsable de l'utilisation de votre Compte Utilisateur et
          vous vous engagez à respecter les lois et les règlements applicables.
          Vous devez également respecter les règles énoncées dans le Contrat et
          fournir à VETALYST les justificatifs nécessaires.
        </p>
      ),
      order: 1,
    },

    {
      title: "9. SERVICE D'ANNUAIRE",
      content: (
        <p>
          Le Service d'Annuaire vous permet de rechercher un Acteur de santé
          selon différents critères. VETALYST ne garantit pas que l'annuaire
          contient tous les Acteurs de santé. Le Service d'Annuaire n'est pas un
          service d'adressage de Patients aux Acteurs de Santé.
        </p>
      ),
      order: 1,
    },

    {
      title: "10. SERVICE DE PRISE DE RENDEZ-VOUS EN LIGNE",
      content: (
        <p>
          Vous pouvez prendre un rendez-vous en ligne avec un Acteur de Santé en
          utilisant le Service de Prise de Rendez-Vous en Ligne. VETALYST ne
          peut être tenu responsable de l'annulation ou de l'indisponibilité de
          l'Acteur de Santé.
        </p>
      ),
      order: 1,
    },

    {
      title: "11. PROTECTION DES DONNÉES À CARACTÈRE PERSONNEL",
      content: (
        <p>
          Les données personnelles recueillies sont traitées par VETALYST
          Tunisie pour la fourniture des Services et l'envoi d'informations
          relatives au Service. Vous pouvez accéder à vos données personnelles
          et les modifier sur le Site.
        </p>
      ),
      order: 1,
    },

    {
      title: "12. COOKIES",
      content: (
        <p>
          Consultez la politique relative aux Cookies pour plus d'informations
          sur l'utilisation des cookies par VETALYST.
        </p>
      ),
      order: 1,
    },

    {
      title: "13. TRANSFERT DE DONNÉES VERS L'APPLICATION",
      content: (
        <p>
          En utilisant les Services, vous autorisez VETALYST à transférer vos
          données nécessaires pour une utilisation optimale des Services.
        </p>
      ),
      order: 1,
    },

    {
      title: "14. RÉSILIATION",
      content: (
        <p>
          VETALYST peut résilier tout ou partie des Services sans indemnité. En
          cas de faute grave de l'Utilisateur, VETALYST peut résilier tout ou
          partie des Services. Vous pouvez résilier le Contrat en demandant la
          suppression de votre Compte Utilisateur.
        </p>
      ),
      order: 1,
    },

    {
      title: "15. PROPRIÉTÉ INTELLECTUELLE",
      content: (
        <p>
          Les Services et les éléments qui les composent sont la propriété
          exclusive de VETALYST. Vous avez un droit d'utilisation personnel de
          la Plateforme BEITARY, mais vous ne pouvez pas copier, reproduire ou
          modifier la Plateforme.
        </p>
      ),
      order: 1,
    },

    {
      title: "16. MENTIONS LÉGALES",
      content: (
        <p>
          BEITARY est exploitée par VETALYST, société SUARL dont le siège social
          est situé à Route Gremda km 8 3012 Sfax.
        </p>
      ),
      order: 1,
    },

    {
      title:
        "17. RÈGLEMENT AMIABLE - DROIT APPLICABLE ET ATTRIBUTION DE COMPÉTENCE",
      content: (
        <p>
          En cas de litige, VETALYST et l'Utilisateur chercheront à régler à
          l'amiable leurs différends. À défaut d'accord amiable, tout litige
          sera soumis à la juridiction des Tribunaux compétents selon le droit
          tunisien.
        </p>
      ),
      order: 1,
    },

    {
      title: "18. DIVERS",
      content: (
        <p>
          VETALYST peut envoyer des enquêtes de satisfaction aux Utilisateurs
          pour évaluer la qualité des Services, mais la participation à ces
          enquêtes est facultative.
        </p>
      ),
      order: 1,
    },
  ];
  return (
    <Container size="md">
      <Stack pt="xl">
        <Title mb="xl" mt="xl">
          Conditions Générales d'Utilisation
        </Title>
        {pp.map((p) => (
          <div>
            <Title order={p.order === 1 ? 2 : 3}>{p.title}</Title>
            <Text>{p.content}</Text>
          </div>
        ))}
      </Stack>
    </Container>
  );
};
