import { Box, Group, Select, Stack, Text, TextInput } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";

// | "microchip-implantation"
// | "topical-medications"
// | "dental-treatment"
// | "nail-trim"

const serviceCostMap: { [x: string]: number } = {
  "coupe griffes": 20,
  "prise de sang": 40,
  toilettage: 80,
};
type ServiceChoice =
  | "coupe griffes" // 20dt
  | "prise de sang" // 40dt
  | "toilettage"; // 80dt

export const LostRevenueCalculator = () => {
  const { t } = useTranslation();
  const [nbOfVets, setNbOfVets] = useState(2);
  const [service, setService] = useState<ServiceChoice>("coupe griffes");

  const [costOfService, setCostOfService] = useState(20);
  const [frequencyPerDay, setFrequencyPerDay] = useState(3);

  const lostRevenue = nbOfVets * costOfService * frequencyPerDay * 200;

  return (
    <Stack>
      <Group position="apart" align="flex-end" my="xl">
        <TextInput
          w={210}
          size="md"
          value={nbOfVets}
          onChange={(v) => {
            try {
              const x = Number(v.currentTarget.value);
              if (!isNaN(x)) setNbOfVets(x);
            } catch (error) {}
          }}
          label={t("Nombre de vétos")}
        />
        <Text color="cyan" weight="bold">
          X
        </Text>
        <Select
          w={210}
          size="md"
          label={t("Service")}
          value={service}
          data={[
            {
              value: "coupe griffes",
              label: t("coupe griffes"),
            },
            {
              value: "prise de sang",
              label: t("prise de sang"),
            },
            {
              value: "toilettage",
              label: t("toilettage"),
            },
          ]}
          onChange={(v) => {
            if (v) {
              setCostOfService(serviceCostMap[v]);
              setService(v as ServiceChoice);
            }
          }}
        />
        <Text color="cyan" weight="bold">
          X
        </Text>
        <TextInput
          w={210}
          size="md"
          value={costOfService}
          onChange={(v) => {
            try {
              const x = Number(v.currentTarget.value);
              if (!isNaN(x)) setCostOfService(x);
            } catch (error) {}
          }}
          label={t("Coût du service")}
        />
        <Text color="cyan" weight="bold">
          X
        </Text>
        <TextInput
          w={210}
          size="md"
          value={frequencyPerDay}
          onChange={(v) => {
            try {
              const x = Number(v.currentTarget.value);
              if (!isNaN(x)) setFrequencyPerDay(x);
            } catch (error) {}
          }}
          label={t("Fréquence par jour")}
        />
      </Group>
      <Box>
        <Text size="lg">{t("Revenu perdu")}</Text>
        <Group>
          <Box
            miw={160}
            h={60}
            px="xl"
            sx={{ borderRadius: 8, background: "#15aabf" }}
          >
            <Text color="white" size={28} align="center" m={6}>
              {lostRevenue.toLocaleString()}
            </Text>
          </Box>
          <Text color="cyan" size={38}>
            {t("/ an")}
          </Text>
        </Group>
      </Box>
    </Stack>
  );
};
