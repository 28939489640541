import {
  Box,
  Button,
  Container,
  Group,
  Space,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import axios from "axios";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { z } from "zod";

export const ScheduleDemo = () => {
  const { t } = useTranslation();
  ///////////////////////
  const formSchema = z.object({
    email: z.string().email(),
    phoneNumber: z.string(),
    name: z.string(),
    job: z.string(),
    practiceName: z.string(),
    howDidYouHear: z.string(),
    city: z.string(),
    state: z.string(),
  });

  type Submission = {
    subject: string;
    html: string;
  };

  type FormValues = z.infer<typeof formSchema>;

  const rules = zodResolver(formSchema);

  ///////////////////
  const [submitState, setSubmitState] = useSubmitState();

  const form = useForm<FormValues>({
    validate: rules,
  });

  const submit = async (values: FormValues) => {
    const url =
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1" ||
      window.location.hostname === ""
        ? "http://127.0.0.1:5001/beitary-app-dev/europe-west1/api"
        : "https://europe-west1-beitary-app-prod.cloudfunctions.net/api"; // !!!! TODO?? fix this to load url from config file

    const api = axios.create({
      headers: {
        "content-type": "application/json",
      },
    });
    api.defaults.baseURL = url;

    const {
      city,
      email,
      howDidYouHear,
      job,
      name,
      phoneNumber,
      practiceName,
      state,
    } = values;
    const data: Submission = {
      subject: "Beitary.com - Demo Request",
      html: `<p>Email: ${email}</p>
      <p>Phone Number: ${phoneNumber}</p>
      <p>First Name: ${name}</p>
      <p>Job Title: ${job}</p>
      <p>Practice Name: ${practiceName}</p>
      <p>How Did You Hear About Us: ${howDidYouHear}</p>
      <p>City: ${city}</p>
      <p>State: ${state}</p>`,
    };

    setSubmitState("pending-response");
    try {
      const result = await api.post(`/emailAdmin`, data);
      console.log(result);
    } catch (error) {
      console.log(error);
    }

    setSubmitState("success");
  };

  return (
    <Container mt="xl" pt="xl">
      <Text color="cyan" size={40} align="center">
        {t("Réserver une démo !")}
      </Text>
      <Text align="center" my="xl" size={20}>
        {t(
          "Utiliser Beitary, c'est simple - mais vous n'avez pas à nous croire sur parole. Entrez vos informations ci-dessous et nous vous contacterons pour planifier une démonstration personnalisée."
        )}
      </Text>
      <Space h={40} />
      <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
        <Stack>
          <Box>
            <Stack p="xl">
              <TextInput
                required
                label={t("Nom")}
                {...form.getInputProps("name")}
              />
              <TextInput
                required
                label={t("Email")}
                {...form.getInputProps("email")}
              />
              <TextInput
                required
                label={t("Numéro de téléphone")}
                {...form.getInputProps("phoneNumber")}
              />
              <TextInput
                required
                label={t("Rôle ou titre professionnel")}
                {...form.getInputProps("job")}
              />
              <TextInput
                required
                label={t("Nom du cabinet / de la clinique")}
                {...form.getInputProps("practiceName")}
              />
              <TextInput
                required
                label={t("Comment avez-vous entendu parler de Beitary")}
                {...form.getInputProps("howDidYouHear")}
              />
              <TextInput
                required
                label={t("Gouvernorat / État")}
                {...form.getInputProps("state")}
              />
              <TextInput
                required
                label={t("Ville")}
                {...form.getInputProps("city")}
              />
            </Stack>
          </Box>
          {submitState !== "success" && (
            <Group position="right">
              <Button
                type="submit"
                loading={submitState === "pending-response"}
                color={form.isValid() ? "cyan" : "gray"}
              >
                {t("Soumettre")}
              </Button>
            </Group>
          )}
          {submitState === "success" && (
            <Text align="center" my="xl" color="cyan.9" size={16}>
              {t(
                "Merci pour votre demande de démonstration ! Un membre de notre équipe vous contactera bientôt pour planifier un rendez-vous."
              )}
            </Text>
          )}
          <Space h={60} />
        </Stack>
      </form>
    </Container>
  );
};
