import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../../baseRecord";
import { UnableToNormalizeData } from "../../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { invoiceStatusEnum } from "./Invoice.status";

/**
 * schema and types versions
 * from first to last
 */

// v0

const invoiceDataV0Schema = z.object({
  status: invoiceStatusEnum,
  checkedOutAt: z.number().int().positive().optional(),
  clientId: z.string(),
  clientName: z.string(),
  patientId: z.string().optional(),
  patientName: z.string().optional(),
  clientDiscount: z.number().int().nonnegative().optional(),
  taxExempt: z.boolean().optional(), // remove tax amoount from taxable items
  subtotal: z.number().int().nonnegative(), // sum total of all invoice line items before discounts
  discount: z.number().int().nonnegative(), // sum total of all discounts on all invoice line items
  tax: z.number().int().nonnegative(), // sum total of tax on all taxable items
  total: z.number().int().nonnegative(), // Subtotal - Discount + Tax (Total amount client is/was responsible for prior to any payments being made)
  paid: z.number().int().nonnegative(), // sum of payments made toward za invoice
  refunded: z.number().int().nonnegative(), // sum of refunds processed associated with the invoice
  balanceDue: z.number().int(), // amount still owed by client
  // notes:z.string().optional(), // client notes: should ne done like notes for tx
  // internalNotes:z.string().optional(),should ne done like notes for tx
  // activity log
});

type InvoiceDataV0 = z.infer<typeof invoiceDataV0Schema>;

const invoiceV0Schema = baseRecordSchema.merge(invoiceDataV0Schema);

type InvoiceV0 = z.infer<typeof invoiceV0Schema>;

const normalizeInvoiceV0 = (data: any): Invoice => {
  try {
    const invoiceV0: InvoiceV0 = invoiceV0Schema.parse(data);

    const { ...rest } = invoiceV0;

    // here it's same object cause current v is 0
    const invoice: Invoice = {
      ...rest,
    };
    return invoice;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const INVOICE_VERSION = "0";

export type InvoiceData = InvoiceDataV0;

export type Invoice = BaseRecord & InvoiceData;

export const invoiceDataSchema = invoiceDataV0Schema;

export const invoiceSchema = invoiceV0Schema;

export const normalizeInvoice = (data: any): Invoice => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeInvoiceV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
