import { Container, Stack, Text, Title } from "@mantine/core";
import { ReactNode } from "react";

export const PP = () => {
  const pp: { title: string; content: ReactNode; order: number }[] = [
    {
      title: "Contexte",
      content: (
        <p>
          Chez VETALYST, nous considérons que la confiance de nos utilisateurs
          est primordiale. Ainsi, la sécurité et la confidentialité de leurs
          données personnelles sont notre priorité absolue.
          <br />
          Nous nous engageons à respecter toutes les réglementations tunisiennes
          relatives à la protection des données personnelles.
          <br />
          Nous avons mis en place une politique stricte pour garantir la
          protection des données de santé de nos utilisateurs, conformément à la
          Loi organique n° 2004-63 du 27 juillet 2004.
          <br />
        </p>
      ),
      order: 1,
    },
    {
      title: "Objet de la présente politique",
      content: (
        <p>
          Cette politique a pour but de vous informer sur la manière dont nous
          protégeons vos données personnelles traitées via notre site
          https://www.beitary.com ou notre application mobile.
          <br />
          Elle décrit comment nous traitons les données personnelles de nos
          utilisateurs lors de leur navigation sur le site ou de leur
          utilisation de notre application.
          <br />
          Cette politique peut être modifiée en cas d'évolution légale, mais les
          données personnelles de nos utilisateurs seront toujours traitées
          conformément à la politique en vigueur au moment de leur collecte,
          sauf prescription légale contraire.
        </p>
      ),
      order: 1,
    },
    {
      title: "Identité et coordonnées des responsables de traitement",
      content: (
        <p>
          VETALYST est le responsable du traitement de vos données personnelles.
          Nous prenons toutes les mesures nécessaires pour assurer leur
          protection et confidentialité, conformément à la Loi organique n°
          2004-63 du 27 juillet 2004.
        </p>
      ),
      order: 1,
    },
    {
      title: "Collecte et origine des données",
      content: (
        <p>
          Toutes les données concernant nos utilisateurs sont collectées
          directement auprès d'eux, par VETALYST.
          <br />
          Nous nous engageons à obtenir leur consentement ou à leur permettre de
          s'opposer à l'utilisation de leurs données pour certaines finalités,
          si nécessaire.
        </p>
      ),
      order: 1,
    },
    {
      title: "Finalité des données collectées",
      content: (
        <p>
          Les données personnelles de nos utilisateurs sont principalement
          traitées pour permettre leur navigation sur le site, leur mise en
          relation avec un professionnel de santé, ainsi que la visualisation et
          la gestion de leur parcours de soins.
          <br />
          Ces données sont également collectées pour prévenir la fraude
          informatique, améliorer la navigation sur le site, et effectuer des
          statistiques sur l'utilisation de notre outil.
        </p>
      ),
      order: 1,
    },
    {
      title: "Types de données traitées",
      content: (
        <p>
          Nous utilisons les données à caractère personnel de nos utilisateurs
          pour différentes finalités, telles que la prise de rendez-vous en
          ligne et la gestion de compte utilisateur. Voici les types de données
          à caractère personnel que nous utilisons pour chaque finalité, ainsi
          que la base légale sur laquelle elles sont traitées et la durée de
          conservation des données :
        </p>
      ),
      order: 1,
    },
    {
      title: "1. Prise de rendez-vous en ligne",
      content: (
        <p>
          <ul>
            <li>
              Données utilisées : nom, prénom, numéro / mail, motifs de
              consultation (facultatif), nom et prénom du praticien, spécialité
              du praticien, date du rendez- vous
            </li>
            <li>Base légale : Exécution d'un contrat (CGU)</li>
            <li>
              Durée de conservation : jusqu'à demande de suppression de
              l'utilisateur.
            </li>
          </ul>
        </p>
      ),
      order: 2,
    },
    {
      title: "2. Gestion de compte utilisateur",
      content: (
        <p>
          <ul>
            <li>
              Données utilisées : nom, prénom, mot de passe, adresse email,
              numéro de téléphone, données de compte (date et heure de création)
            </li>
            <li>Base légale : Exécution d'un contrat (CGU)</li>
            <li>
              Durée de conservation : jusqu'à demande de suppression de
              l'utilisateur.
            </li>
          </ul>
        </p>
      ),
      order: 2,
    },
    {
      title: "3. Utilisation des applications et des appareils",
      content: (
        <p>
          <ul>
            <li>
              Données utilisées : données de connexion et d'utilisation de la
              plateforme (date et heure de visite du site, ID de session),
              matériel informatique utilisé pour la navigation, adresse IP
            </li>
            <li>Base légale : Intérêt légitime</li>
            <li>
              Durée de conservation : jusqu'à demande de suppression de
              l'utilisateur.
            </li>
          </ul>
        </p>
      ),
      order: 2,
    },
    {
      title: "4. Prévention et lutte contre la fraude informatique",
      content: (
        <p>
          <ul>
            <li>
              Données utilisées : données de connexion et d'utilisation du site,
              matériel informatique utilisé pour la navigation, adresse IP
            </li>
            <li>Base légale : Intérêt légitime</li>
            <li>
              Durée de conservation : jusqu'à demande de suppression de
              l'utilisateur.
            </li>
          </ul>
        </p>
      ),
      order: 2,
    },
    {
      title: "5. Demandes de droit",
      content: (
        <p>
          <ul>
            <li>
              Données utilisées : nom, prénom, adresse email, contenu de la
              demande, pièce d'identité (lorsque nécessaire)
            </li>
            <li>Base légale : Obligation légale</li>
            <li>
              Durée de conservation : jusqu'à demande de suppression de
              l'utilisateur.
            </li>
          </ul>
        </p>
      ),
      order: 2,
    },

    {
      title: "Non-communication des données personnelles",
      content: (
        <p>
          Nous vous assurons que vos données personnelles ne seront pas
          transmises à des acteurs commerciaux ou publicitaires. Cependant, nous
          pouvons faire appel à des sociétés spécialisées pour certaines
          prestations telles que le mailing ou l'analyse d'audience. Dans ce
          cas, nous avons conclu des contrats spécifiques avec ces sociétés pour
          encadrer et sécuriser le transfert de vos données.
        </p>
      ),
      order: 1,
    },
    {
      title: "Durée de conservation des données",
      content: (
        <p>
          Nous conservons vos données personnelles jusqu'à votre demande de
          suppression, conformément aux prescriptions légales.
        </p>
      ),
      order: 1,
    },
    {
      title: "Les droits des utilisateurs",
      content: (
        <p>
          Vous disposez de certains droits concernant vos données personnelles,
          conformément à la réglementation en vigueur. Vous avez notamment le
          droit d'accéder à vos données, de les rectifier, de les effacer, de
          limiter leur traitement, de vous opposer à leur traitement, de les
          transférer à un tiers et de définir leur sort après votre décès. En
          cas de décès, nous nous engageons à détruire vos données, sauf si leur
          conservation est nécessaire pour des fins probatoires ou pour répondre
          à une obligation légale.
          <br />
          Pour exercer vos droits, vous pouvez nous contacter par écrit à
          l'adresse suivante : VETALYST – Route Gremda km 8 3012 Sfax ou par
          mail à contact@vetalyst.com. Veuillez vous identifier de manière
          précise.
        </p>
      ),
      order: 1,
    },
    {
      title: "Sécurité",
      content: (
        <p>
          Nous mettons en place toutes les mesures techniques et
          organisationnelles nécessaires pour assurer la sécurité de vos données
          personnelles et préserver leur confidentialité. Nous utilisons des
          procédés d'authentification avec accès personnel et sécurisé via des
          identifiants et mots de passe confidentiels. Certaines données sont
          également chiffrées pour renforcer la sécurité.
        </p>
      ),
      order: 1,
    },
    {
      title: "Informations personnelles et mineurs",
      content: (
        <p>
          Notre site et notre application sont destinés aux personnes majeures
          capables de contracter des obligations légales. Les utilisateurs
          mineurs de moins de 16 ans ou incapables doivent obtenir le
          consentement préalable de leur responsable légal avant de saisir leurs
          données sur notre site et notre application. Sauf obligation légale de
          confidentialité, nous pourrons informer directement le responsable
          légal des catégories spécifiques de données personnelles recueillies
          auprès de la personne mineure et de la possibilité de s'opposer à la
          collecte, l'utilisation ou la conservation de ces données.
        </p>
      ),
      order: 1,
    },
    {
      title: "Nous contacter",
      content: (
        <p>
          Si vous avez des questions, des réclamations ou des recommandations
          concernant notre politique de protection des données personnelles,
          vous pouvez nous contacter par écrit à l'adresse suivante : VETALYST –
          Route Gremda km 8 3012 Sfax ou par mail à contact@vetalyst.com. Nous
          restons à votre disposition pour améliorer la qualité de notre
          politique de confidentialité.
        </p>
      ),
      order: 1,
    },
  ];
  return (
    <Container size="md">
      <Stack pt="xl">
        <Title mb="xl" mt="xl">
          Politique de protection des données personnelles
        </Title>
        {pp.map((p) => (
          <>
            <Title order={p.order === 1 ? 2 : 3}>{p.title}</Title>
            <Text>{p.content}</Text>
          </>
        ))}
      </Stack>
    </Container>
  );
};
