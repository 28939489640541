import { lsu } from "beitary-shared";
import ar from "i18n/locales/AR.json";
import en from "i18n/locales/EN.json";
import fr from "i18n/locales/FR.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

/**
 * Do not import the t() function directly from i18next
 * This sounds strange, but the t() from i18next operates
 * outside of react-i18next and React component lifecycles.
 * Strings will not translate if called before async translations
 * are loaded and will not update if language is changed.
 * sauce: https://medium.com/@danduan/translating-react-apps-using-i18next-d2f78bc87314
 */

const fallbackLng = ["FR"];

const lng = lsu.getItemFromLocalStorage<string>("btr_web_lan") ?? undefined;

const resources = {
  EN: {
    label: "English",
    translation: en,
  },
  AR: {
    label: "العربية",
    translation: ar,
  },
  FR: {
    label: "Français",
    translation: fr,
  },
};

i18n.use(initReactI18next).init(
  {
    resources,
    fallbackLng,
    lng,

    react: {
      useSuspense: false, // TODO inspect
    },

    // supportedLngs: availableLanguages,

    //debug: true,

    interpolation: {
      escapeValue: false,
    },
  },
  (err, t) => {
    if (err)
      return console.log("something went wrong loading translation", err);
    t("LANG");
  }
);

export default i18n;
