import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { accountStatusEnum } from "../AccountStatus";
import { accountCustomClaims } from "./Account.customClaims";

/**
 * schema and types versions
 * from first to last
 */

// v0

const accountDataV0Schema = z.object({
  status: accountStatusEnum,
  name: z.string(),
  email: z.string().email(),
  phoneNumber: z.string(),
  phoneNumber_countryCode: z.string(),
  pictureURL: z.string().url().optional(),
  // address
  address_Line1: z.string().optional(),
  address_Line2: z.string().optional(),
  address_city: z.string().optional(),
  address_stateOrProvince: z.string().optional(),
  address_postalCode: z.string().optional(),
  address_countryCode: z.string().optional(),
  customClaims: accountCustomClaims.optional(),
});

type AccountDataV0 = z.infer<typeof accountDataV0Schema>;

const accountV0Schema = baseRecordSchema.merge(accountDataV0Schema);

type AccountV0 = z.infer<typeof accountV0Schema>;

const normalizeAccountV0 = (data: any): Account => {
  try {
    const accountV0: AccountV0 = accountV0Schema.parse(data);

    const { ...rest } = accountV0;

    // here it's same object cause current v is 0
    const account: Account = {
      ...rest,
    };
    return account;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const ACCOUNT_VERSION = "0";

export type AccountData = AccountDataV0;

export type Account = BaseRecord & AccountData;

export const accountDataSchema = accountDataV0Schema;

export const accountSchema = accountV0Schema;

export const normalizeAccount = (data: any): Account => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeAccountV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
