import dayjs from "dayjs";
import locale_ar_tn from "dayjs/locale/ar-tn";
import locale_en from "dayjs/locale/en";
import locale_fr from "dayjs/locale/fr";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import tz from "dayjs/plugin/timezone"; // dependent on utc plugin
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(weekOfYear);

// we need locales for time/date inputs (see Mantine dates provider)

// couldnt figure out how to export first day of week and weekends
// asking for type DayOfWeek which is not exported from mantine

const applyTimeConfig = (tz: string, lang: string) => {
  const getTimeConfig = (timezone: string, language: string) => {
    //console.log(`called configTime with tz ${tz} and lang ${lang}`);

    switch (language) {
      case "AR":
        return {
          locale: "ar-tn",
          locale_data: locale_ar_tn,
          firstDayOfWeek: 0,
          weekendDays: [0],
          timezone,
        };
      case "FR":
        return {
          locale: "fr",
          locale_data: locale_fr,
          firstDayOfWeek: 0,
          weekendDays: [0],
          timezone,
        };
      case "EN":
        return {
          locale: "en",
          locale_data: locale_en,
          firstDayOfWeek: 0,
          weekendDays: [0],
          timezone,
        };

      default:
        return {
          locale: "ar-tn",
          locale_data: locale_ar_tn,
          firstDayOfWeek: 0,
          weekendDays: [0],
          timezone,
        };
    }
  };

  const { locale_data, timezone, locale, firstDayOfWeek } = getTimeConfig(
    tz,
    lang
  );

  // Apply the locale
  dayjs.locale(locale_data);
  dayjs.tz.setDefault(timezone);

  return { locale, firstDayOfWeek };
};

// const { locale_data, timezone } = configTime();
// apply locale
// console.log(locale_data.name, timezone);

//applyLocale(locale_data, timezone);

const MS_PER_DAY = 1000 * 60 * 60 * 24;

const dateDiffInDays = (epoch1: number, epoch2: number) => {
  return Math.floor((epoch1 - epoch2) / MS_PER_DAY);
};

const dateDiffInMs = (epoch1: number, epoch2: number) => {
  const datejs1 = dayjs.utc(epoch1).hour(0).minute(0).second(0).millisecond(0);
  const datejs2 = dayjs.utc(epoch2).hour(0).minute(0).second(0).millisecond(0);
  return datejs2.diff(datejs1);
};

const getHoursAndMinutesString = (epoch: number) => {
  return dayjs(epoch).format("LT");
};

const getDateAndTimeString = (epoch: number) => {
  const dayjsString = dayjs(epoch).format("LLL");
  return dayjsString;
};

const getDateString = (epoch: number) => {
  const dayjsString = dayjs(epoch).format("LL");
  return dayjsString;
};

const getLocaleDateStringFromEpoch = (epoch: number) => {
  return getDate(epoch).toLocaleDateString();
};

const getDate = (epoch: number) => {
  const date = dayjs(epoch).toDate();
  return date;
};

const getDateTime = (date: Date) => {
  const time = dayjs(date).toDate().valueOf();
  return time;
};

const getCurrentDate = () => {
  const date = dayjs().toDate();
  return date;
};

const getCurrentDateTime = () => {
  const time = dayjs().valueOf();

  return time;
};

const getStartOfDate = (date: Date) => {
  return dayjs(date).startOf("date").toDate().valueOf();
};

const getDateValueAtHMSM = (
  datee: Date,
  hours: number,
  minutes: number,
  seconds: number,
  milliseconds: number
) => {
  const date = dayjs(datee)
    .hour(hours)
    .minute(minutes)
    .second(seconds)
    .millisecond(milliseconds)
    .valueOf();
  return date;
};

const combineDateAndTime = ({ date, time }: { date: number; time: number }) => {
  const datejs = dayjs(date);
  const timejs = dayjs(time);
  const combined = datejs
    .set("hour", timejs.hour())
    .set("minute", timejs.minute())
    .set("second", 0)
    .set("millisecond", 0);
  return combined.valueOf();
};

const getAgeInYMD = (epoch: number) => {
  // sauce https://asprunner.com/forums/topic/27702-javascript-to-calculate-age-in-years-months-and-days
  var ctrldob = dayjs(epoch).toDate();

  var ctrldoa = new Date();

  var diff = new Date(ctrldoa.getTime() - ctrldob.getTime());

  const years = diff.getUTCFullYear() - 1970; // Gives difference as year
  const months = diff.getUTCMonth(); // Gives month count of difference
  const days = diff.getUTCDate() - 1; // Gives day count of difference

  return [years, months, days];
};

const getStartOfWeek = (date: Date) => {
  return dayjs(date).startOf("week").toDate().valueOf();
};

const cleanTimeSecondsAndMillis = (epoch: number) => {
  return dayjs(epoch).startOf("minute").valueOf();
};

const getDayOfWeek = (epoch: number) => {
  const x = dayjs(epoch).day(); // TODO this doesnt take timezone/locale into configuration
  return x === 0 ? 6 : x - 1;
};

const getHour = (epoch: number) => {
  return dayjs(epoch).hour();
};

const getYearWeeksCoveredStrings = ({
  from,
  to,
}: {
  from: number;
  to: number;
}) => {
  const weeks = [];
  const startDate = dayjs(from);
  const endDate = dayjs(to);
  let currentDate = startDate.startOf("week");
  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, "week")) {
    weeks.push(currentDate.year() + "-" + currentDate.week());
    currentDate = currentDate.add(1, "week");
  }
  return weeks;
};

const getYearWeekString = (epoch: number) => {
  const theDate = dayjs(epoch);
  return theDate.year() + "-" + theDate.week();
};

const calculateNextDateTime = ({
  timeSinceEpoch,
  dueIn,
  timeUnit,
}: {
  timeSinceEpoch: number;
  dueIn: number;
  timeUnit: "DAY" | "WEEK" | "MONTH" | "YEAR";
}) => {
  const baseDate = dayjs(timeSinceEpoch);

  switch (timeUnit) {
    case "DAY":
      return baseDate.add(dueIn, "day").valueOf();
    case "WEEK":
      return baseDate.add(dueIn, "week").valueOf();
    case "MONTH":
      return baseDate.add(dueIn, "month").valueOf();
    case "YEAR":
      return baseDate.add(dueIn, "year").valueOf();
    default:
      throw new Error("Invalid time unit");
  }
};

export const tu = {
  MS_PER_DAY,
  dateDiffInDays,
  dateDiffInMs,
  getHoursAndMinutesString,
  getDateAndTimeString,
  getDateString,
  getDate,
  getDateTime,
  getCurrentDate,
  getCurrentDateTime,
  getStartOfDate,
  getStartOfWeek,
  getDateValueAtHMSM,
  combineDateAndTime,
  getAgeInYMD,
  getLocaleDateStringFromEpoch,
  applyTimeConfig,
  cleanTimeSecondsAndMillis,
  getDayOfWeek,
  getHour,
  getYearWeeksCoveredStrings,
  getYearWeekString,
  calculateNextDateTime,
};
