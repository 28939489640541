import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../../baseRecord";
import { UnableToNormalizeData } from "../../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { patientSexEnum, patientSpeciesEnum } from "../../../patients";
import { appointmentLocationEnum } from "../AppointmentLocation";
import { appointmentStatusEnum } from "../AppointmentStatus";

/**
 * schema and types versions
 * from first to last
 */

// v0

const appointmentDataV0Schema = z.object({
  status: appointmentStatusEnum,
  location: appointmentLocationEnum,
  reasonForConsultation: z.string(),
  organizationId: z.string(),
  organizationName: z.string(),
  serviceProviderId: z.string(),
  serviceProviderName: z.string(),
  supervisingDoctorId: z.string().optional(),
  supervisingDoctorName: z.string().optional(),
  //
  clientId: z.string(),
  clientName: z.string(),
  patientId: z.string(),
  patientName: z.string(),
  patientSpecies: patientSpeciesEnum,
  patientSex: patientSexEnum,
  patientPictureURL: z.string().url().optional(),
  time: z.number().positive(),
  duration: z.number().positive().int(), // in minutes
  appointmentTypeId: z.string(),
  emailNotif: z.boolean().optional(),
  smsNotif: z.boolean().optional(),
  // recurr stuff
  // t("NO")
  // t("WEEKLY")
  // t("BIWEEKLY")
  // t("MONTHLY")
  // t("BIMONTHLY")
  // t("QUARTERLY")
  // t("BIANNUALLY")
  // t("ANNUALLY")
  recurring: z.enum([
    "NO",
    "WEEKLY",
    "BIWEEKLY",
    "MONTHLY",
    "BIMONTHLY",
    "QUARTERLY",
    "BIANNUALLY",
    "ANNUALLY",
  ]),
  onMon: z.boolean().optional(),
  onTue: z.boolean().optional(),
  onWed: z.boolean().optional(),
  onThu: z.boolean().optional(),
  onFri: z.boolean().optional(),
  onSat: z.boolean().optional(),
  onSun: z.boolean().optional(),
  // todo ends after?
  // chatgpt:
  /**
   * In addition to the "after" option with a number input field for specifying the number of occurrences, some other options that could be included in the "ends" dropdown for a recurrent appointment are:
   * "on a specific date" - This option allows the user to select a specific end date for the recurrent appointment series.
   * "never" - This option indicates that the appointment series will continue indefinitely, without any specified end date or number of occurrences.
   * "until further notice" - This option indicates that the appointment series will continue until the user specifies otherwise.
   * file:///C:/MyFolder/Git/capstone/shepherd-help/help.shepherd.vet/i/o/639243853/8b06016f0982263e9e3e526a/image.png
   * file:///C:/MyFolder/Git/capstone/shepherd-help/help.shepherd.vet/i/o/478093741/393111500e0ef0e009ee7ff0/Screen%20Shot%202022-03-10%20at%207.49.32%20AM.png
   */
});

type AppointmentDataV0 = z.infer<typeof appointmentDataV0Schema>;

const appointmentV0Schema = baseRecordSchema.merge(appointmentDataV0Schema);

type AppointmentV0 = z.infer<typeof appointmentV0Schema>;

const normalizeAppointmentV0 = (data: any): Appointment => {
  try {
    const appointmentV0: AppointmentV0 = appointmentV0Schema.parse(data);

    const { ...rest } = appointmentV0;

    // here it's same object cause current v is 0
    const appointment: Appointment = {
      ...rest,
    };
    return appointment;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error, data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const APPOINTMENT_VERSION = "0";

export type AppointmentData = AppointmentDataV0;

export type Appointment = BaseRecord & AppointmentData;

export const appointmentDataSchema = appointmentDataV0Schema;

export const appointmentSchema = appointmentV0Schema;

export const normalizeAppointment = (data: any): Appointment => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeAppointmentV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
