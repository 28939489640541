import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../../baseRecord";
import { UnableToNormalizeData } from "../../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

const bStorageFileCustomMetadataDataV0Schema = z.object({
  originalName: z.string(),
  readOrgIds: z.string(),
  organizationId: z.string(),
  clientId: z.string().optional(),
  shareWithClient: z.enum(["true", "false"]), // boolean
  shareReadWithSelectedOrgs: z.enum(["true", "false"]), // boolean
});

type BStorageFileCustomMetadataDataV0 = z.infer<
  typeof bStorageFileCustomMetadataDataV0Schema
>;

const bStorageFileCustomMetadataV0Schema = baseRecordSchema.merge(
  bStorageFileCustomMetadataDataV0Schema
);

type BStorageFileCustomMetadataV0 = z.infer<
  typeof bStorageFileCustomMetadataV0Schema
>;

const normalizeBStorageFileCustomMetadataV0 = (
  data: any
): BStorageFileCustomMetadata => {
  try {
    const bStorageFileCustomMetadataV0: BStorageFileCustomMetadataV0 =
      bStorageFileCustomMetadataV0Schema.parse(data);

    const { ...rest } = bStorageFileCustomMetadataV0;

    // here it's same object cause current v is 0
    const bStorageFileCustomMetadata: BStorageFileCustomMetadata = {
      ...rest,
    };
    return bStorageFileCustomMetadata;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const BSTORAGE_FILE_CUSTOM_METADATA_VERSION = "0";

export type BStorageFileCustomMetadataData = BStorageFileCustomMetadataDataV0;

export type BStorageFileCustomMetadata = BaseRecord &
  BStorageFileCustomMetadataData;

export const bStorageFileCustomMetadataDataSchema =
  bStorageFileCustomMetadataDataV0Schema;

export const bStorageFileCustomMetadataSchema =
  bStorageFileCustomMetadataV0Schema;

export const normalizeBStorageFileCustomMetadata = (
  data: any
): BStorageFileCustomMetadata => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeBStorageFileCustomMetadataV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
