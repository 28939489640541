import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { currencyEnum } from "../../common";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { subscriptionStatusEnum } from "./Subscription.status";
import { subscriptionTypeEnum } from "./Subscription.type";

/**
 * schema and types versions
 * from first to last
 */

// v0

// inspiration: https://stripe.com/docs/api/subscriptions/object

const subscriptionDataV0Schema = z.object({
  status: subscriptionStatusEnum,
  type: subscriptionTypeEnum,
  description: z.string(),
  currency: currencyEnum.default("TND"),
  currentPeriodEnd: z.number().int().positive().optional(),
  currentPeriodStart: z.number().int().positive().optional(),
  customerId: z.string(),
  dueDate: z.number().int().positive().optional(),
  dueAmount: z.number().int().positive().optional(),
  endAt: z.number().int().optional(),
  endAtPeriodEnd: z.boolean().optional(),
  endedAt: z.number().int().positive().optional(),
  latestInvoiceId: z.string().optional(),
  startDate: z.number().int().positive(),
  trialStartAt: z.number().int().positive().optional(),
  trialEndAt: z.number().int().positive().optional(),
});

type SubscriptionDataV0 = z.infer<typeof subscriptionDataV0Schema>;

const subscriptionV0Schema = baseRecordSchema.merge(subscriptionDataV0Schema);

type SubscriptionV0 = z.infer<typeof subscriptionV0Schema>;

const normalizeSubscriptionV0 = (data: any): Subscription => {
  try {
    const subscriptionV0: SubscriptionV0 = subscriptionV0Schema.parse(data);

    const { ...rest } = subscriptionV0;

    // here it's same object cause current v is 0
    const subscription: Subscription = {
      ...rest,
    };
    return subscription;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const SUBSCRIPTION_VERSION = "0";

export type SubscriptionData = SubscriptionDataV0;

export type Subscription = BaseRecord & SubscriptionData;

export const subscriptionDataSchema = subscriptionDataV0Schema;

export const subscriptionSchema = subscriptionV0Schema;

export const normalizeSubscription = (data: any): Subscription => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeSubscriptionV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
