import { z } from "zod";

export enum SubsType {
  pack1 = "a",
  pack2 = "b",
  pack3 = "c",
  pack4 = "d",
  pack5 = "e",
}

export const subscriptionTypeEnum = z.nativeEnum(SubsType);
export type SubscriptionType = z.infer<typeof subscriptionTypeEnum>;
